
const initialState = {
  import_integration: [],
}

const coachboard_import_integrations = (state = initialState, action) => {
  const { import_integration } = state
  switch (action.type) {
    case 'GET_IMPORT_INTEGRATIONS':
      return { ...state, import_integration: action.import_integration }
    case 'DESTROY_IMPORT_INTEGRATION':
        // get staff index that was deleted
        const deletedImportIntegrationIndex = import_integration.findIndex(({ id }) => id === action.id)
        // add deleted_at to deleted staff
        // get actual deleted_at value from server
        const deletedImportIntegration = {
          ...import_integration[deletedImportIntegrationIndex],
          deleted_at: new Date().toISOString(),
        }
        // replace previous state with updated version
        return {
          ...state,
          import_integration: [
            ...import_integration.slice(0, deletedImportIntegrationIndex),
            deletedImportIntegration,
            ...import_integration.slice(deletedImportIntegrationIndex + 1),
          ],
        }
    case 'REACTIVATE_IMPORT_INTEGRATION':
      const updatedImportIntegrationIndex = import_integration.findIndex(({ id }) => id === action.id)
      const updatedImportIntegration = {
        ...import_integration[updatedImportIntegrationIndex],
        deleted_at: null,
      }
      return {
        ...state,
        import_integration: [
          ...import_integration.slice(0, updatedImportIntegrationIndex),
          updatedImportIntegration,
          ...import_integration.slice(updatedImportIntegrationIndex + 1),
        ],
      }
    case 'ADD_IMPORT_INTEGRATION':
      return { ...state, import_integration: [ ...state.import_integration, action.import_integration ] }
    case 'UPDATE_IMPORT_INTEGRATION':
      return {
        ...state,
        import_integration: state.import_integration.map(s => s.id === action.import_integration.id ? action.import_integration : s),
      }
    default:
      return state
  }
}

export default coachboard_import_integrations
