const initialState = {
  institutes: [],
  selectedInstitute: '',
  loading: false,
}

const coachboard_institutes = (state=initialState, action) => {
  switch (action.type) {
    case 'INSTITUTES':
      return { ...state, institutes: action.institutes }
    case 'FETCH_INSTITUTES':
      return { ...state, loading: true }
    case 'FETCH_INSTITUTES_COMPLETE':
      return { ...state, loading: false }
    case 'SELECT_INSTITUTE':
      return { ...state, selectedInstitute: action.instituteId }
    case 'ADD INSTITUTE':
      return { ...state, institutes: [ ...state.institutes, action.institute ] }
    case 'UPDATE INSTITUTE':
      return { ...state, institutes: state.institutes.map(institute => institute.id === action.institute.id ? action.institute : institute) }
    case 'DESTROY INSTITUTE':
      return { ...state, institutes: state.institutes.filter(institute => institute.id !== action.id) }
    default:
      return state
  }
}

export default coachboard_institutes
