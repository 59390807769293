import {
    LOAD_FILTERS,
    LOADED_FILTERS,
    LOADING_FILTERS,
    ADD_FILTER,
    DELETE_FILTER,
    UPDATE_FILTER,
  } from '../actions/types'
  
  const initialState = {
    filters: [],
    loading: true,
  }
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOAD_FILTERS:
        return { ...state, filters: action.filters }
      case ADD_FILTER:
        return { ...state, filters: [...state.filters, action.filter] }
      case DELETE_FILTER:
        return { ...state, filters: [...state.filters.filter(item => item.id != action.filter.id) ] }
      case UPDATE_FILTER:
        return { ...state, filters: [...state.filters.filter(item => item.id != action.filter.id), action.filter] }
      case LOADING_FILTERS:
        return { ...state, loading: true }
      case LOADED_FILTERS:
        return { ...state, loading: false }
      default:
        return state;
    }
  }
  