import React from 'react'
import PropTypes from 'prop-types'
import './Flash.css'

const TwitterDMFlash = ({ clearFlash, data, userKind }) => {
  return (
    <div className="flash twitter">
      <div className="message-content">
        <i className="fa fa-twitter twitter-flash-icon fa-2x" aria-hidden="true"></i>
        <span className="message-type">message</span>
        <span className="message-body">
          {(userKind !== "coach") && `${data.staff.name}: `}
          {`New DM from ${data.contact.title}`}
        </span>
      </div>
      <div className="line">
      </div>
      <i className="fa fa-times close-flash" onClick={clearFlash}></i>
    </div>
  )
}

TwitterDMFlash.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TwitterDMFlash
