import React from "react"
import { connect } from "react-redux"
import { CLOSE_CONTEXT_MENU } from "../../actions/types"

class ContextMenu extends React.Component {
  
  handleContextClick = (e, x, y) => {

  }

  componentDidMount() {
    const { close } = this.props
    document.addEventListener("contextmenu", (e) => {
      if(e.clientX === window.contextX && e.clientY === window.contextY) {
        return
      }
      else if(window.contextVisible) {
        close()
      }
    })
  }

  componentDidUpdate() {
    const { open, close, x, y } = this.props
    window.contextX = x
    window.contextY = y
    window.contextVisible = open
    if(open) {
      document.addEventListener("click", close)
    }
    else {
      document.removeEventListener("click", close)
    }
  }

  render() {
    const {x, y, actions, close, open } = this.props
    let menuStyle = {
      'top': `${y}px`,
      'left': `${x+5}px`,
      'borderStyle': 'ridge',
    }

    //Default form that is not open
    let theForm = <div id="context-menu"></div>

    //The form when it is open
    if (open) {
      theForm = <div id="context-menu" style={menuStyle}>
        { actions.map((action, index) => {
          return (
            <p key={index} onClick={(e) => { e.preventDefault(); close(); action.callback(e); }}>
              {action.name}
            </p>
          )
        })}
      </div>
    }

    return (theForm)
  }
}

const mapStateToProps = (state, props) =>
{
  const {x, y, contextMenuOpen, contextMenuActions} = state.contextMenu;
  return {
    x: x,
    y: y,
    open: contextMenuOpen,
    actions: contextMenuActions
  }
}

const mapDispatchToProps = (dispatch) =>
{
  return {
    close: () => dispatch({type: CLOSE_CONTEXT_MENU})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu)