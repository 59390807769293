import {
  ADD_TO_RECIPIENTS,
  REMOVE_FROM_RECIPIENTS,
  ADD_ALL_TO_RECIPIENTS,
  REMOVE_ALL_FROM_RECIPIENTS,
  REMOVE_SET_FROM_RECIPIENTS,
} from "../actions/types";

import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'
import reject from 'ramda/src/reject'
import compose from 'ramda/src/compose'
import flip from 'ramda/src/flip'
import includes from 'ramda/src/includes'

const initialState = {
  recipients: []
}

export default (state=initialState, action) => {
  switch (action.type) {
    case ADD_TO_RECIPIENTS: {
      const recipients = uniqBy(prop('id'), [...state.recipients, action.contact])
      return { ...state, recipients }
    }
    case REMOVE_FROM_RECIPIENTS: {
      const recipients = state.recipients.filter(c => c.id !== action.contact.id)
      return { ...state, recipients }
    }
    case ADD_ALL_TO_RECIPIENTS: {
      const recipients = uniqBy(prop('id'), [...state.recipients, ...action.contacts])
      return { ...state, recipients }
    }
    case REMOVE_ALL_FROM_RECIPIENTS:
      return { ...state, recipients: [] }
    case REMOVE_SET_FROM_RECIPIENTS:
      const ids = action.contacts.map(c => c.id)
      return {...state, recipients: reject(compose(flip(includes)(ids), prop('id')), state.recipients)}
    default:
      return state
  }
}
