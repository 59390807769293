import {
    REPLACE_COACH_FIELDS,
    RECEIVE_COACH_FIELDS,
    REFRESH_COACH_AVATAR
  } from '../actions/types'
  import axios from 'axios'
  import { setFlash, setErrorFlash } from './flashActions'

  export const loadCoachFields = () => (dispatch) => {
    axios.get(`/api/v1/staffs/for_manager_maint`)
      .then(res => dispatch({ type: RECEIVE_COACH_FIELDS, field_names: res.data }))
      .catch(err => {
        dispatch(setErrorFlash(err))
      })
  }

 export const replaceCoachFields = (orderedFields, fallbackFields) => dispatch => {
    dispatch({ type: REPLACE_COACH_FIELDS, fields: orderedFields })
    axios.put(`/api/v2/staffs/update_order`, { fields: orderedFields.map(f => ({ id: f.id, row_order: f.row_order })) })
      .then(({ data }) => {
        return { ok: true }
      })
      .catch(err => {
        dispatch({ type: REPLACE_COACH_FIELDS, fields: fallbackFields })
        dispatch(setErrorFlash(err))
        return { ok: false }
      })
  }

  export const refreshAvatar = (coachId) => dispatch =>
    axios.post(`/api/v2/staffs/refresh_avatar`, {refresh: true}, { headers: { onbehalf: coachId } })
      .then(({ data: { picture } }) => {
        dispatch({ type: REFRESH_COACH_AVATAR, coachId, picture })
        dispatch(setFlash(`Coach Avatar Refreshed.`, 'success'))
      })
      .catch(response => {
        dispatch(setErrorFlash(response))
      })