import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './Flash.css'

const LoadingFlash = ({
  display
}) => {
  if (!display) return null
  return (
    <div className="loading-flash">
        <div className="message">
        <h2>Uploading...</h2>
        </div>
    </div>
  )
}

LoadingFlash.propTypes = {
  display: PropTypes.bool
}

const mapStateToProps = state => ({
  display: state.loadingFlash.display
})

export default connect(mapStateToProps)(LoadingFlash)
