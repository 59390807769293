import {
  RECEIVE_WID_FIELDS,
  RECEIVE_INACTIVE_WID_FIELDS,
  CREATE_WID_FIELD,
  REPLACE_WID_FIELDS,
  UPDATE_WID_LABEL,
  DELETE_WID,
  ACTIVATE_WID,
  RECEIVE_WID_FUNCTIONS
} from '../actions/types'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'

const initialState = {
  fields: [],
  inactive: [],
  isLoaded: false,
  whistleIdFunctions: [],
  whistleIdGameFunctions: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_WID_FUNCTIONS:
      return { ...state, whistleIdFunctions: action.wids.whistle_id_functions, whistleIdGameFunctions: action.wids.whistle_id_game_functions }
    case RECEIVE_WID_FIELDS:
      return { ...state, fields: action.field_names, isLoaded: true }
    case RECEIVE_INACTIVE_WID_FIELDS:
      return { ...state, inactive: action.wids }
    case CREATE_WID_FIELD:
      return { ...state, fields: [...state.fields, action.wid] }
    case REPLACE_WID_FIELDS:
      const fields = [...action.fields, ...state.fields]
      return { ...state, fields: uniqBy(prop('id'), fields) }
    case UPDATE_WID_LABEL:
      return { ...state, fields: state.fields.map(f => f.id === action.id ? { ...f, label: action.label } : f) }
    case DELETE_WID:
      return {
        ...state,
        fields: state.fields.filter(f => f.id !== action.wid.id),
        inactive: [action.wid, ...state.inactive],
      }
    case ACTIVATE_WID:
      return {
        ...state,
        fields: [...state.fields, action.wid],
        inactive: state.inactive.filter(f => f.id !== action.wid.id),
      }
    default:
      return state
  }
}
