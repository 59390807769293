import axios from 'axios'
import {
    LOAD_TEAM_INFO,
    UPDATE_TEAM_INFO,
} from '../types'

import { setErrorFlash, setFlash } from '../flashActions'

export const loadTeamInfo = (teamId) => dispatch =>
  axios.get(`/api/v2/teams/${teamId}`)
    .then(({data}) => {
      dispatch({ type: LOAD_TEAM_INFO, info: data.data })
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
    })

export const updateTeamInfo = (teamInfo, coachId) => dispatch => {
  return axios.put(`/api/v2/teams/update`, teamInfo, { headers: { onbehalf: coachId } })
    .then(({data}) => {
        dispatch({ type: UPDATE_TEAM_INFO, teamInfo: data.data})
        dispatch(setFlash('Team default compression quality option has been updated.', 'success'))
        return ( { ok: true } )
    })
    .catch(err => {
        dispatch(setErrorFlash(err))
        return ( { ok: false } )
    })
}
