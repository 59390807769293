
import {
    LOAD_TEAM_INFO,
    UPDATE_TEAM_INFO
  } from '../actions/types'
  
  const initialState = {
  }
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOAD_TEAM_INFO:
        return { ...state, ...action.info }
      case UPDATE_TEAM_INFO:
        return { ...state, ...action.teamInfo }
      default:
        return state;
    }
  }
