import {
  GET_AUTHENTICATIONS,
  REMOVE_AUTHENTICATION,
  LOADING_AUTHENTICATIONS,
  NOT_LOADING_AUTHENTICATIONS,
  CLEAR_AUTHENTICATIONS,
} from '../actions/types'

const defaultState = {
  loading: false,
  auths: [],
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case LOADING_AUTHENTICATIONS:
      return { ...state, loading: true }
    case NOT_LOADING_AUTHENTICATIONS:
      return { ...state, loading: false }
    case GET_AUTHENTICATIONS:
      return { ...state, auths: action.auths }
    case REMOVE_AUTHENTICATION: {
      const auths = state.auths.filter(a => a.id !== action.authId)
      return { ...state, auths: auths, loading: false }
    }
    case CLEAR_AUTHENTICATIONS:
      return defaultState
    default:
      return state
  }
}
