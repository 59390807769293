import {
  LOAD_CONTACTS_REQUEST,
  LOAD_CONTACTS_SUCCESS,
  LOAD_CONTACTS_FAILURE,
  LOAD_NEW_CONTACT,
  LOAD_UPDATED_CONTACT,
  DESTROY_ALL_CONTACTS,
  EXPORTING_CONTACTS_REQUEST,
  EXPORTING_CONTACTS_COMPLETE,
  LOAD_UPDATED_CONTACT_FOLLOWING,
} from '../actions/types'

import filter from 'ramda/src/filter'
import flip from 'ramda/src/flip'
import includes from 'ramda/src/includes'
import compose from 'ramda/src/compose'
import prop from 'ramda/src/prop'
import not from 'ramda/src/not'

const initialState = {
  contacts: [],
  pages: 0,
  total: 0,
  loading: false,
  exporting: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_CONTACTS_REQUEST:
      return { ...state, loading: true }
    case LOAD_CONTACTS_SUCCESS:
      return { ...state, contacts: action.contacts, pages: action.pages, total: action.total, loading: false }
    case LOAD_CONTACTS_FAILURE:
      return { ...state, loading: false }
    case EXPORTING_CONTACTS_REQUEST:
      return { ...state, exporting: true }
    case EXPORTING_CONTACTS_COMPLETE:
      return { ...state, exporting: false }
    case LOAD_NEW_CONTACT:
        return { ...state, contacts: [...state.contacts, action.contact] }
    case LOAD_UPDATED_CONTACT_FOLLOWING:
      return { ...state, contacts: state.contacts.map(c => action.contact_ids.find(id => c.id == id) ? {...c, following: action.following} : c)}
    case LOAD_UPDATED_CONTACT:
      return { ...state, contacts: [...state.contacts.filter(c => !action.contacts.map(a=>a.id).includes(c.id)), ...action.contacts]}
    case DESTROY_ALL_CONTACTS: {
      const contacts =
        // filters list of deleted ids from current list of contacts
        filter(
          compose(
            not,
            flip(includes)(action.contacts_ids),
            prop('id')
          ),
          state.contacts
        )
      return { ...state, contacts }
    }
    default:
      return state;
  }
}
