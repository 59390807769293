import React from 'react'
import PropTypes from 'prop-types'
import './Flash.css'

const SMSFlash = ({ clearFlash, data, userKind }) => {
  return (
    <div className="flash success">
      <div className="message-content">
        <i className="fa fa-mobile sms-flash-icon fa-2x" aria-hidden="true"></i>
        <span className="message-type">message</span>
        <span className="message-body">
          {(userKind !== "coach") && `${data.staff.name}: `}
          {`New SMS from ${data.contact.title}`}
        </span>
      </div>
      <div className="line">
      </div>
      <i className="fa fa-times close-flash" onClick={clearFlash}></i>
    </div>
  )
}

SMSFlash.propTypes = {
  data: PropTypes.object.isRequired,
  clearFlash: PropTypes.func.isRequired,
  userKind: PropTypes.string.isRequired,
}

export default SMSFlash
