import {
  TEAM_WIDS,
  INACTIVE_TEAM_WIDS,
  ADD_TEAM_WID,
  DESTROY_TEAM_WID,
  ACTIVATE_TEAM_WID,
  UPDATE_TEAM_WID
} from './types'

import axios from 'axios'
import { setFlash, setErrorFlash } from './flashActions'


export const loadTeamWids = () => dispatch =>
  axios.get(`/api/v2/team_whistleids`)
    .then(res => {
      dispatch({ type: TEAM_WIDS, team_wids: res.data })
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
    })

export const loadInactiveTeamWids = () => dispatch =>
  axios.get('/api/v2/team_whistleids/inactive')
    .then(res => {
      dispatch({ type: INACTIVE_TEAM_WIDS, team_wids: res.data })
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const createTeamWid = team_wid => dispatch =>
{
  return axios.post(`/api/v2/team_whistleids/`, { team_whistleid: {label: team_wid.label, value: team_wid.value } })
    .then(res => {
      dispatch({ type: ADD_TEAM_WID, team_wid: res.data })
      dispatch(setFlash('Team Whistle Id Created.'))
      return true
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return false
    })
}

export const updateTeamWid = team_wid => dispatch => 
  axios.put(`/api/v2/team_whistleids/${team_wid.id}`, { team_whistleid: team_wid })
    .then(res => {
      dispatch({ type: UPDATE_TEAM_WID, team_wid: res.data })
      dispatch(setFlash('Team Whistle Id Updated.'))
      return { ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

export const deleteTeamWid = id => dispatch =>
  axios.delete(`/api/v2/team_whistleids/${id}/delete`)
    .then (res => {
      dispatch({type: DESTROY_TEAM_WID, team_wid: res.data})
      dispatch(setFlash('Team Whistle Id Deleted.'))
      return { ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

export const activateTeamWid = id => dispatch =>
    axios.put(`/api/v2/team_whistleids/${id}/activate`)
      .then(({ data }) => {
        dispatch({ type: ACTIVATE_TEAM_WID, team_wid: data })
      })
      .catch(err => {
        dispatch(setErrorFlash(err))
      })
