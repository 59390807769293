import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import Modal from 'react-modal'
import store from './store'
import App from './containers/App'
import './normalize.css'
import 'font-awesome/css/font-awesome.css'
import './index.css'
// import registerServiceWorker from './registerServiceWorker';
import { createBrowserHistory } from "history";
//import { loadpageview } from "./actions/googleAnalytics"

const history = createBrowserHistory()

//This is setup for Google Analytics
history.listen(location => {
  //additional loads
  //loadpageview(window.location.pathname, false);
})

class WhistleApp extends Component {

  //This is setup for Google Analytics
  componentDidMount() {
    //Initialize the page load
    //loadpageview(window.location.pathname, true);
  }

  render() {
    return (
  <Provider store={store}>
    <Router history={history}>
      <Route component={App}/>
    </Router>
  </Provider>
  )
  }
}

Modal.setAppElement('#root') //in order to block content behind modal, for screen readers
ReactDOM.render(<WhistleApp />, document.getElementById('root'));
// registerServiceWorker();
