import {
  SET_SCHEDULED,
  SET_SCHEDULED_LOADED,
  REMOVE_SCHEDULED,
  RESET_SCHEDULED
} from "../actions/types"

const initialState = {
  messages: [],
  isLoaded: false
}

export default (state=initialState, action) => {
  switch (action.type) {
    case SET_SCHEDULED: {
      return { ...state, messages: action.messages }
    }
    case SET_SCHEDULED_LOADED: {
      return { ...state, isLoaded: action.loaded }
    }
    case REMOVE_SCHEDULED: {
      const messages = state.messages.filter(m => m.id !== action.id)
      return { ...state, messages }
    }
    case RESET_SCHEDULED: {
      return initialState
    }
    default:
      return state
  }
}
