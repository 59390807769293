const initialState = {
  report: {
    calls: [],
    messages: [],
    totalTime: ''
  },
  reportLoading: false,
  reportLoaded: false
}

export default (state=initialState, action) => {
  switch (action.type) {
    case 'FETCH_REPORT':
      return { ...state, reportLoading: true, reportLoaded: false }
    case 'GET_REPORT':
      return { ...state, report: action.report, reportLoaded: true }
    case 'FETCH_REPORT_COMPLETE':
      return { ...state, reportLoading: false }
    case 'RESET_REPORT':
      return initialState
    default:
      return state
  }
}
