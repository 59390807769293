import {
  GET_SIDEKIQ_SCHEDULES,
  FETCH_SIDEKIQ_SCHEDULES,
  FETCH_SIDEKIQ_SCHEDULES_COMPLETE,
  UPDATE_SIDEKIQ_SCHEDULE
 } from '../../actions/types'

const initialState = {
  sidekiq_schedules: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SIDEKIQ_SCHEDULES:
      return {...state, sidekiq_schedules: action.sidekiq_schedules}
    case FETCH_SIDEKIQ_SCHEDULES:
      return { ...state, loading: true }
    case FETCH_SIDEKIQ_SCHEDULES_COMPLETE:
      return { ...state, loading: false }
    case UPDATE_SIDEKIQ_SCHEDULE:
      return {...state,
        sidekiq_schedules: state.sidekiq_schedules.map(s => s.key === action.sidekiq_schedule.key ? action.sidekiq_schedule : s)}
    default:
      return state
  }
}