const initialState = {
  analytics: [],
  page: 1,
  canLoadMore: false,
  loading: false,
  totalPages: 0
}

export default (state=initialState, action) => {
  switch (action.type) {
    case 'FETCH_ANALYTICS':
      return { ...state, page: action.page, loading: true }
    case 'GET_ANALYTICS':
      return { ...state, analytics: action.data.analytics, totalPages: action.data.total_pages }
    case 'FETCH_ANALYTICS_COMPLETE':
      return { ...state, loading: false }
    default:
      return state
  }
}
