import {
  SET_FLASH,
  CLEAR_FLASH
} from './types';
import { setHeaders } from './headers'

var flashTimeOut;

export const setFlash = (message, messageType, time = 3000, data) => dispatch => {
  dispatch({ type: SET_FLASH, message, messageType, data })
  clearTimeout(flashTimeOut)
  flashTimeOut = setTimeout(() => dispatch({ type: CLEAR_FLASH }), time)
}

export const setErrorFlash = (res, time = 5000) => dispatch => {
  if (res.response && res.response.data && res.response.data.message) {
    dispatch(setFlash(res.response.data.message, 'error', time))
  } else {
    dispatch(setFlash(`${res.response ? res.response.statusText : ''}${res.message ? `: ${res.message}` : ''}`, 'error', time))
  }
  dispatch(setHeaders(res.headers))
}

export const setErrorFlashWithMessage = (res, message, time = 5000) => dispatch => {
  if (res.response && res.response.data && res.response.data.message) {
    dispatch(setFlash(`${res.response.data.message}: ${message}`, 'error', time))
  } else {
    dispatch(setFlash(`${res.response ? res.response.statusText : ''}${res.message ? `: ${res.message}` : ''}: ${message}`, 'error', time))
  }
  dispatch(setHeaders(res.headers))
}

export const setErrorFlashMessage = (res, time = 5000) => dispatch => {
  dispatch(setFlash(res, 'error', time))
}

export const clearFlash = () => dispatch => dispatch({ type: CLEAR_FLASH })
