const initialState = {
  contacts: [],
  loading: false,
}

export default (state=initialState, action) => {
  switch (action.type) {
    case 'FETCHING_CONTACTS':
      return { ...state, contacts: [], loading: true }
    case 'GET_CONTACTS':
      return { ...state, contacts: action.contacts }
    case 'FETCHING_CONTACTS_COMPLETE':
      return { ...state, loading: false }
    case 'RESET_CONTACTS':
      return initialState
    default:
      return state
  }
}
