import {
  GET_IMPORT_RESULTS,
  BEGIN_IMPORT,
  IMPORT_ERROR,
} from '../actions/types'

const initialState = {
  result: {
    error_records: [],
    new_records: [],
    updated_records: []
  },
  importing: false,
  importComplete: false
}

export default (state=initialState, action) => {
  switch (action.type) {
    case GET_IMPORT_RESULTS:
      return { ...state, result: action.data.result, importing: false, importComplete: true }
    case BEGIN_IMPORT:
      return { ...state, importing: true }
    case IMPORT_ERROR:
      return { ...state, importing: false, importComplete: true }
    default:
      return state
  }
}
