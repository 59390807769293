import React, { useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { BaseUrlContext } from '../containers/App'

// Context providers in ManagerMainContent and CoachMainContent
export const withBaseUrl = Component => props => (
  <BaseUrlContext.Consumer>
    {baseUrl => <Component {...props} baseUrl={baseUrl}/>}
  </BaseUrlContext.Consumer>
)

export const FlashContext = React.createContext()

const flashInitialState = {
  display: false,
  messageType: '',
  message: '',
  data: {},
}

export const FlashProvider = props => {
  const [flashState, setFlashState] = useState(() => flashInitialState)
  const flashTimeOut = useRef(null)

  const clearFlash = () => setFlashState(flashInitialState)

  const setFlash = (message, messageType, time = 3000, data) => {
    setFlashState({ display: true, message, messageType })
    clearTimeout(flashTimeOut.current)
    flashTimeOut.current = setTimeout(() => clearFlash(), time)
  }

  const setErrorFlash = (res, time = 5000) => {
    if (res.response && res.response.data && res.response.data.message) {
      setFlash(res.response.data.message, 'error', time)
    } else {
      setFlash(`${res.response ? res.response.statusText : ''}${res.message ? `: ${res.message}` : ''}`, 'error', time)
    }
  }

  const memoValue = useMemo(() => ({ flashState, clearFlash, setFlash, setErrorFlash }), [flashState])

  return (
    <FlashContext.Provider value={memoValue}>
      {props.children}
    </FlashContext.Provider>
  )
}

FlashProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
