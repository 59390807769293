import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearFlash } from '../../actions/flashActions'
import { FlashContext } from '../../reference/contextUtils'
import TwitterDMFlash from './TwitterDMFlash'
import InstagramDMFlash from './InstagramDMFlash'
import SMSFlash from './SMSFlash'
import './Flash.css'

const NewFlash = ({
  userKind,
}) => {
  const { flashState, clearFlash } = useContext(FlashContext)

  if (!flashState.display) return null
  if (flashState.messageType === 'message') {
    if (flashState.data.kind === 'twitter_dm') {
      return <TwitterDMFlash data={flashState.data} userKind={userKind} clearFlash={clearFlash} />
    }
    if (flashState.data.kind === 'instagram_dm') {
      return <InstagramDMFlash data={flashState.data} userKind={userKind} clearFlash={clearFlash} />
    }
    if (flashState.data.kind === 'message') {
      return <SMSFlash data={flashState.data} userKind={userKind} clearFlash={clearFlash} />
    }
  }
  return (
    <div className={`flash ${flashState.messageType}`}>
      <div className="message-content">
        <span className="message-type">{flashState.messageType}</span>
        <span className="message-body">{flashState.message}</span>
      </div>
      <div className="line"></div>
      <i className="fa fa-times close-flash" onClick={clearFlash}></i>
    </div>
  )
}

NewFlash.propTypes = {
  userKind: PropTypes.string,
}

const mapStateToProps = state => ({
  userKind: state.user.kind,
})

export default connect(mapStateToProps, { clearFlash })(NewFlash)
