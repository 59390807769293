import React from 'react'
import classnames from 'classnames'
import './loader.scss'

const Loader = ({ containerClass, small, icon, small_icon }) =>
  <div className={`loading-container ${containerClass}`}>
    <div className="loading-indicator-container">
      <div className={classnames('loader', { "loader-small": small }, { "loader-icon": icon }, { "loader-small-icon": small_icon })}></div>
    </div>
  </div>

export default Loader
