import {
  CLEAR_MM_FORM,
  UPDATE_MM_FORM,
  RECEIVE_MESSAGE_PREVIEW,
  SET_MM_FORM_FROM_PREVIEW,
  REMOVE_FROM_RECIPIENTS,
  LOADING_MESSAGE_PREVIEW,
  DONE_LOADING_MESSAGE_PREVIEW,
  CLEAR_MM_PREVIEW,
  FETCHING_SENDER_FROM_CONTACT,
  FETCHING_SENDER_FROM_CONTACT_DONE
} from '../actions/types'

const initialState = {
  id: null,
  preview: [],
  kind: '',
  body: '',
  schedule_at: '',
  expire_at: '',
  schedule_on_hold: false,
  schedule_local_timezone: false,
  picture: '',
  attachment: {},
  storageFileId: null,
  loadingPreview: false,
  massMessageGrouped: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MM_FORM: {
      URL.revokeObjectURL(state.attachment.preview)
      return initialState
    }
    case UPDATE_MM_FORM:
      return { ...state, [action.key]: action.value }
    case RECEIVE_MESSAGE_PREVIEW:
      return { ...state, preview: action.data.messages }
    case CLEAR_MM_PREVIEW:
      return { ...state, preview: [] }
    case SET_MM_FORM_FROM_PREVIEW: {
      let { body, id, picture, kind, schedule_at, schedule_on_hold, schedule_local_timezone, storage_file_id, from, from_coach_type, mass_message_grouped, picture_type, picture_name, expire_at } = action.message
      return {
        ...state,
        id,
        body,
        schedule_at,
        expire_at,
        schedule_on_hold,
        schedule_local_timezone: false,
        picture,
        kind,
        storageFileId: storage_file_id,
        from,
        from_coach_type,
        massMessageGrouped: mass_message_grouped,
        mediaType: picture_type,
        mediaFileName: picture_name,
      }
    }
    case REMOVE_FROM_RECIPIENTS: {
      const preview = state.preview.filter(p => p.to.id !== action.contact.id)
      return { ...state, preview }
    }
    case LOADING_MESSAGE_PREVIEW:
      return { ...state, preview: [], loadingPreview: true }
    case DONE_LOADING_MESSAGE_PREVIEW:
      return { ...state, loadingPreview: false }
    case FETCHING_SENDER_FROM_CONTACT:
      return {...state, loadingPreview: true}
    case FETCHING_SENDER_FROM_CONTACT_DONE:
        return {...state, loadingPreview: false}
    default:
      
      return state
  }
}
