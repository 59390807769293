import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearFlash } from '../../actions/flashActions'
import TwitterDMFlash from './TwitterDMFlash'
import InstagramDMFlash from './InstagramDMFlash'
import SMSFlash from './SMSFlash'
import './Flash.css'

const Flash = ({
  display,
  clearFlash,
  messageType,
  message,
  data,
  userKind,
}) => {
  if (!display) return null
  if (messageType === 'message') {
    if (data.kind === 'twitter_dm') {
      return <TwitterDMFlash data={data} userKind={userKind} clearFlash={clearFlash} />
    }
    if (data.kind === 'instagram_dm') {
      return <InstagramDMFlash data={data} userKind={userKind} clearFlash={clearFlash} />
    }
    if (data.kind === 'message') {
      return <SMSFlash data={data} userKind={userKind} clearFlash={clearFlash} />
    }
  }
  return (
    <div className={`flash ${messageType}`}>
      <div className="message-content">
        <span className="message-type">{messageType}</span>
        <span className="message-body">{message}</span>
      </div>
      <div className="line">
      </div>
      <i className="fa fa-times close-flash" onClick={clearFlash}></i>
    </div>
  )
}

Flash.propTypes = {
  messageType: PropTypes.oneOf(['success', 'error', 'warning', 'information', 'message', '']),
  message: PropTypes.string,
  display: PropTypes.bool,
  clearFlash: PropTypes.func,
  data: PropTypes.object,
  userKind: PropTypes.string,
}

const mapStateToProps = state => ({
  display: state.flash.display,
  messageType: state.flash.messageType,
  message: state.flash.message,
  data: state.flash.data,
  userKind: state.user.kind,
})

export default connect(mapStateToProps, { clearFlash })(Flash)
