const initialState = {
  report: {
    calls: [],
    messages: [],
    topReportAll: [],
    topReportPicsOnly: [],
    totalTime: ''
  },
  reportLoading: false,
  reportLoaded: false
}

export default (state=initialState, action) => {
  switch (action.type) {
    case 'FETCH_REPORT_COACHBOARD':
      return { ...state, reportLoading: true }
    case 'GET_REPORT_COACHBOARD':
      return { ...state, report: action.report, reportLoaded: true }
    case 'FETCH_REPORT_COMPLETE_COACHBOARD':
      return { ...state, reportLoading: false }
    case 'RESET_REPORT_COACHBOARD':
      return initialState
    default:
      return state
  }
}
