import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'
import id from 'date-fns/locale/id'

const initialState = {
  broadcasts: [],
  loading: false,
  selectedStaffList: [],
}

const coachboard_broadcasts = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BROADCASTS':
      return { ...state, broadcasts: action.broadcasts }
    case 'FETCH_BROADCASTS':
      return { ...state, loading: true }
    case 'FETCH_BROADCASTS_COMPLETE':
      return { ...state, loading: false }
    case 'SELECT_BROADCAST':
      return { ...state, selectedBroadcasts: action.broadcastId }
    case 'ADD_BROADCAST':
      return { ...state, broadcasts: [ ...state.broadcasts, action.broadcast ] }
    case 'UPDATE_BROADCAST':
      return { ...state, broadcasts: state.broadcasts.map(b => b.id === action.broadcast.id ? action.broadcast : b) }
    case 'DESTROY_BROADCAST':
      return { ...state, broadcasts: state.broadcasts.filter(b => b.id !== action.id) }
    case 'BROADCAST_ADD_STAFF':
      return { ...state, selectedStaffList: [...state.selectedStaffList, ...(action.selectedStaff.filter(({ id: id1 }) => !state.selectedStaffList.some(({ id: id2 }) => id2 === id1))).map(r => ({id: r.id, name: r.name})) ]}
    case 'BROADCAST_REMOVE_STAFF':
      return { ...state, selectedStaffList: (state.selectedStaffList.filter(({ id: id1 }) => !action.selectedStaff.some(({ id: id2 }) => id2 === id1))).map(r => ({id: r.id, name: r.name}))}
    case 'GET_STAFF_BY_IDS':
      return { ...state, selectedStaffList: action.selectedStaff }
    case 'CLEAR_SELECTED_STAFF_LIST':
      return { ...state, selectedStaffList: [] }
    default:
      return state
  }
}

export default coachboard_broadcasts
