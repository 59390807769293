import {
  RECEIVE_REPORT_DEF,
  CREATE_REPORT_DEF,
  DELETE_REPORT_DEF,
  REPLACE_REPORT_DEF,
  UPDATE_REPORT_DEF,
  RECEIVE_REPORT_DEF_OPTIONS,
  RECEIVE_REPORT_DEF_COLUMNS,
} from '../actions/types'
import axios from 'axios'
import { setFlash, setErrorFlash } from './flashActions'

export const loadReportDefs = () => dispatch =>
  axios.get(`/api/v2/report_defs`)
    .then(res => dispatch({ type: RECEIVE_REPORT_DEF, reports: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const loadReportDefReportTypes = () => dispatch =>
  axios.get(`/api/v2/report_defs/get_options`)
    .then(res => dispatch({ type: RECEIVE_REPORT_DEF_OPTIONS, report_types: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const loadReportDefReportTypeColumns = (report_type) => dispatch =>
  axios.get(`/api/v2/report_defs/get_columns?report_type=${report_type}`)
    .then(res => dispatch({ type: RECEIVE_REPORT_DEF_COLUMNS, report_type_columns: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const createReportDef = (reportObject) => dispatch =>
  axios.post(`/api/v2/report_defs/create`, reportObject)
    .then(({ data }) => {
      dispatch({ type: CREATE_REPORT_DEF, report: data.report })
      dispatch(setFlash(`Whistle ID: ${data.report.name} created`, 'success'))
      return { ok: true }
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return { ok: false }
    })

export const replaceReportDefs = (orderedFields, fallbackFields) => dispatch => {
  dispatch({ type: REPLACE_REPORT_DEF, reports: orderedFields })
  axios.put(`/api/v2/report_defs/update_order`, { reports: orderedFields.map(f => ({ id: f.id, row_order: f.row_order })) })
    .then(({ data }) => {
      return { ok: true }
    })
    .catch(err => {
      dispatch({ type: REPLACE_REPORT_DEF, reports: fallbackFields })
      dispatch(setErrorFlash(err))
      return { ok: false }
    })
}

export const editReportDef = (reportObject) => dispatch =>
  axios.put(`/api/v2/report_defs/${reportObject.id}/update`, reportObject )
    .then(({ data }) => {
      dispatch({ type: UPDATE_REPORT_DEF, id: reportObject.id, report: data.report })
      return { ok: true }
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return { ok: false }
    })

export const deleteReportDef = id => dispatch =>
  axios.delete(`/api/v2/report_defs/${id}/delete`)
    .then(({ data }) => {
      dispatch({ type: DELETE_REPORT_DEF, report: data })
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
    })