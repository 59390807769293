import {
  SET_CONTACT_CONVERSATION,
  CONTACT_UPDATED,
  TOGGLE_FROZEN,
  TOGGLE_PRIORITY,
  UPDATE_WID_PIC,
  REMOVE_WID_PIC,
  SET_CURRENT_CONTACT,
  FETCHING_CURRENT_CONTACT,
  FETCHING_CURRENT_CONTACT_FAILURE,
  TOGGLE_SEND_METHOD,
  CHANGE_CONVERSATION_FILTER,
  CLEAR_CURRENT_CONTACT,
  SET_CURRENT_CONTACT_LISTS,
  FETCHING_CURRENT_CONTACT_LISTS,
  FETCHING_CURRENT_CONTACT_ADD_LISTS,
  SET_CURRENT_CONTACT_ADD_LISTS,
  ADD_TO_CURRENT_CONTACT_LIST,
  REMOVE_FROM_CURRENT_CONTACT_LIST,
  SET_CURRENT_CONTACT_RELATIONSHIPS,
  DELETE_CURRENT_CONTACT_RELATIONSHIP,
  FETCHING_CURRENT_CONTACT_RELATIONSHIPS,
  APPEND_TO_CURRENT_CONTACT_RELATIONSHIP,
  FETCHING_CURRENT_CONTACT_PIPELINE_EVALS,
  PIPELINE_EVALS,
  UPDATE_PIPELINE_EVAL,
  UPDATE_WID_GAMES,
  ADD_CURRENT_CONTACT_TO_PIPELINE,
  UPDATE_AREA_POSITION_COACH
} from "../actions/types";

const initialState = {
  notes: [],
  lists: [],
  fetchingLists: false,
  addLists: [],
  fetchingAddLists: false,
  relationships: [],
  fetchingRelationships: false,
  isLoaded: false,
  metadata: { fields :{first_name: '', last_name: ''}},
  fetching: false,
  phone: true,
  twitter: true,
  apple: true,
  whistle_pics: [],
  sendMethod: 'message',
  wid_games: [],
  analytcis_status: { status: 'black'},
  fetchingPipelineEvals: false,
  pipelineEvals: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CONTACT_CONVERSATION: {
      return {
        ...state,
        ...action.contact,
        isLoaded: true,
      }
    }
    case FETCHING_CURRENT_CONTACT:
      return { ...state, fetching: true }
    case FETCHING_CURRENT_CONTACT_FAILURE:
      return { ...state, fetching: false, isLoaded: true }
    case SET_CURRENT_CONTACT:
      return { ...state, ...action.contact, fetching: false, isLoaded: true }
    case TOGGLE_FROZEN:
      return { ...state, freezed: action.freezed }
    case TOGGLE_PRIORITY:
        return { ...state, priority: action.priority }
    case CONTACT_UPDATED:
      return { ...state, ...action.data }
    case UPDATE_WID_PIC:
      return {
        ...state,
        whistle_pics: [...state.whistle_pics, action.data],
        metadata: { fields: {
          ...state.metadata.fields,
          [action.data.field]: action.data.id
        }}
      }
    case UPDATE_WID_GAMES:
      return { ...state, wid_games: action.data }
    case REMOVE_WID_PIC: {
      const metadata = {...state.metadata, fields: {...state.metadata.fields, [action.data.wid.field]: "" } }
      const whistle_pics = state.whistle_pics.map(wP => wP.id === action.data.wid.id ? action.data.wid : wP)
      return { ...state, whistle_pics, metadata }
    }
    case TOGGLE_SEND_METHOD:
      return { ...state, sendMethod: action.method }
    case CHANGE_CONVERSATION_FILTER:
      return { ...state, phone: action.phone, twitter: action.twitter }
    case FETCHING_CURRENT_CONTACT_LISTS:
      return { ...state, fetchingLists: action.data }
      case SET_CURRENT_CONTACT_LISTS:
      return { ...state, lists: action.data }
    case FETCHING_CURRENT_CONTACT_ADD_LISTS:
      return { ...state, fetchingAddLists: action.data }
    case SET_CURRENT_CONTACT_ADD_LISTS:
      return { ...state, addLists: action.data }
    case ADD_TO_CURRENT_CONTACT_LIST:
      return {
        ...state,
        addLists: state.addLists.filter(l => l.id !== action.data.id),
        lists: [...state.lists, action.data]
      }
    case REMOVE_FROM_CURRENT_CONTACT_LIST:
      return {
        ...state,
        lists: state.lists.filter(l => l.id !== action.data.id),
        addLists: [...state.addLists, action.data]
      }
    case SET_CURRENT_CONTACT_RELATIONSHIPS:
      return { ...state, relationships: action.data }
    case DELETE_CURRENT_CONTACT_RELATIONSHIP:
      return {
        ...state,
        relationships: [...state.relationships.filter(r => r.id !== action.data)]
      }
    case FETCHING_CURRENT_CONTACT_RELATIONSHIPS:
      return { ...state, fetchingRelationships: action.data }
    case ADD_CURRENT_CONTACT_TO_PIPELINE:
      return { ...state, pipelineEvals: [...state.pipelineEvals, action.data] }
    case FETCHING_CURRENT_CONTACT_PIPELINE_EVALS:
      return { ...state, fetchingPipelineEvals: action.data }
    case PIPELINE_EVALS:
      return { ...state, pipelineEvals: action.data }
    case UPDATE_PIPELINE_EVAL: {
      return { ...state, pipelineEvals: action.data}
    }
    case UPDATE_AREA_POSITION_COACH: 
      return {
        ...state,
        metadata: { fields: {
          ...state.metadata.fields,
          ['area_recruiting_coach']: action.data.area_recruiting_coach,
          ['position_recruiting_coach']: action.data.position_recruiting_coach
        }}
      }
    case APPEND_TO_CURRENT_CONTACT_RELATIONSHIP:
      return { ...state, relationships: [...state.relationships, action.data] }
    case CLEAR_CURRENT_CONTACT:
      return { ...initialState }
    default:
      return state;
  }
}
