import {
  RECEIVE_WID_FIELDS,
  RECEIVE_INACTIVE_WID_FIELDS,
  CREATE_WID_FIELD,
  UPDATE_WID_PIC,
  REMOVE_WID_PIC,
  REPLACE_WID_FIELDS,
  UPDATE_WID_LABEL,
  DELETE_WID,
  ACTIVATE_WID,
  UPDATE_WID_GAMES,
  RECEIVE_WID_FUNCTIONS
} from '../actions/types'
import axios from 'axios'
import contains from 'ramda/src/contains'
import { setFlash, setErrorFlash } from './flashActions'

export const loadWIDFields = () => dispatch =>
  axios.get(`/api/v2/whistleids`)
    .then(res => dispatch({ type: RECEIVE_WID_FIELDS, field_names: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const loadWIDFunctions = () => dispatch =>
  axios.get('/api/v2/whistleids/get_whistle_id_functions')
    .then(res => dispatch({ type: RECEIVE_WID_FUNCTIONS, wids: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const loadInactiveWIDFields = () => dispatch =>
  axios.get('/api/v2/whistleids/inactive')
    .then(res => dispatch({ type: RECEIVE_INACTIVE_WID_FIELDS, wids: res.data }))
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const createWIDField = (widObject) => dispatch =>
  axios.post(`/api/v2/whistleids/create`, widObject)
    .then(({ data }) => {
      dispatch({ type: CREATE_WID_FIELD, wid: data.wid })
      dispatch(setFlash(`Whistle ID: ${data.wid.label} created`, 'success'))
      return { ok: true }
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return { ok: false }
    })

 export const updateWIDPic = (whistlePic, file, contact_id) => async dispatch => {
    return axios.put(`/api/v2/importer/update_widpic_nopic`, whistlePic)
     .then(async ({ data: {widpic, put_url} }) => {
       return axios.put(put_url, file, { headers: {'Content-Type':  file.type }})
        .then( () => {
          //If the the file is a video then ask for it to be transcoded
          if(contains('video', widpic.content_type)) {
            //set the initial status since we are going to process the video
            widpic.transcoder_status = 'processing'
            axios.post(
              '/api/v2/importer/transcode_widpic_video', 
              {id: widpic.id, contact_id: contact_id})
                .catch(err => {this.props.setErrorFlash(err)})
          }

          dispatch({ type: UPDATE_WID_PIC, data: widpic })
          dispatch(setFlash(`WhistleID Picture created`, 'success'))
        })
        .catch(err => {
          dispatch(setErrorFlash(err))
        })
     })
     .catch(err => {
        dispatch(setErrorFlash(err))
     })
  }

export const updateWIDGames = (contactId) => dispatch =>
    axios.post(`/api/v2/contacts/${contactId}/get_wid_games`)
      .then(({ data }) => {
        dispatch({ type: UPDATE_WID_GAMES, data })
        //dispatch(setFlash(`WhistleID Games Updated`, 'success'))
        return { ok: true }
      })
      .catch(err => {
        dispatch(setErrorFlash(err))
        return { ok: false }
      })

export const deleteWIDPic = (contactId, widPic) => dispatch =>
  axios.post(`/api/v1/recruits/${contactId}/remove_widpic`, { widPic })
    .then(({ data }) => {
      dispatch({ type: REMOVE_WID_PIC, data })
      dispatch(setFlash(`WhistleID Picture deleted`, 'success'))
      return { ok: true }
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return { ok: false }
    })

export const replaceWIDFields = (orderedFields, fallbackFields) => dispatch => {
  dispatch({ type: REPLACE_WID_FIELDS, fields: orderedFields })
  axios.put(`/api/v2/whistleids/update_order`, { fields: orderedFields.map(f => ({ id: f.id, row_order: f.row_order })) })
    .then(({ data }) => {
      return { ok: true }
    })
    .catch(err => {
      dispatch({ type: REPLACE_WID_FIELDS, fields: fallbackFields })
      dispatch(setErrorFlash(err))
      return { ok: false }
    })
}

export const updateWidLabel = (id, label) => dispatch =>
  axios.put(`/api/v2/whistleids/${id}/update_label`, { label })
    .then(({ data }) => {
      dispatch({ type: UPDATE_WID_LABEL, id, label })
      return true
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return false
    })

export const deleteWid = id => dispatch =>
  axios.delete(`/api/v2/whistleids/${id}/delete`)
    .then(({ data }) => {
      dispatch({ type: DELETE_WID, wid: data })
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const activateWid = id => dispatch =>
  axios.put(`/api/v2/whistleids/${id}/activate`)
    .then(({ data }) => {
      dispatch({ type: ACTIVATE_WID, wid: data })
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
    })
