import {
  LOAD_GAME_INFO,
  LOAD_GAME_INFO_START,
  LOAD_GAME_INFO_END
  } from '../actions/types'
  
  const initialState = {
    games: [],
    paramsUsed: [],
    loading: true,
  }
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOAD_GAME_INFO:
        return { ...state, games: action.games, paramsUsed: action.paramsUsed }
      case LOAD_GAME_INFO_START:
        return { ...state, loading: true }
      case LOAD_GAME_INFO_END:
        return { ...state, loading: false }
      default:
        return state;
    }
  }
  