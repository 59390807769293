import {
  POSITIONS,
  INACTIVE_POSITIONS,
  ADD_POSITION,
  GENERATE_POSITIONS,
  UPDATE_POSITION,
  DESTROY_POSITION,
  ACTIVATE_POSITION,
  UPDATE_POSITION_LABEL
} from '../actions/types'


const initialState = {
  fields: [],
  inactive: [],
  isLoaded: false,
}

export default (state = initialState, action) => {

  switch (action.type) {
    case POSITIONS:
      return { ...state, fields: action.positions, isLoaded: true }
    case INACTIVE_POSITIONS:
      return { ...state, inactive: action.positions }
    case ADD_POSITION:
      return { ...state, fields: [...state.fields, action.position] }
    case GENERATE_POSITIONS:
        return { ...state, fields: [...state.fields, action.position] }
    // case REPLACE_WID_FIELDS:
    //   const fields = [...action.fields, ...state.fields]
    //   return { ...state, fields: uniqBy(prop('id'), fields) }
    case UPDATE_POSITION:
      return { ...state, fields: state.fields.map(p => p.id === action.position.id ? action.position : p) }

    case UPDATE_POSITION_LABEL:
      return { ...state, fields: state.fields.map(f => f.id === action.id ? { ...f, label: action.label } : f) }
      
    case DESTROY_POSITION:
      return {
        ...state,
        fields: state.fields.filter(f => f.id !== action.position.id),
        inactive: [...state.inactive, action.position],
      }
    case ACTIVATE_POSITION:
      return {
        ...state,
        fields: [...state.fields, action.position],
        inactive: state.inactive.filter(f => f.id !== action.position.id),
      }
    default:
      return state
  }
}
