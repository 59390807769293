import {
REPLACE_COACH_FIELDS,
RECEIVE_COACH_FIELDS
} from '../actions/types'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'
  
const initialState = {
  fields: [],
  isLoaded: false,
}
  
export default function(state = initialState, action) {
  switch (action.type) {

    case RECEIVE_COACH_FIELDS:
      return { ...state, fields: action.field_names, isLoaded: true }
    case REPLACE_COACH_FIELDS:
      const fields = [...action.fields, ...state.fields]
      return { ...state, fields: uniqBy(prop('id'), fields) }
  default:
      return state
  }
}