
const initialState = {
  appPermissions: [],
  selectedAppPermission: ''
}

const coachboard_appPermissions = (state = initialState, action) => {
  switch (action.type) {
    case 'APP_PERMISSIONS':
      return { ...state, appPermissions: action.appPermissions }
    case 'UPDATE_APP_PERMISSIONS':
      return { ...state, appPermissions: state.appPermissions.map(t => t.id === action.appPermission.id ? action.appPermission : t) }
    default:
      return state
  }
}

export default coachboard_appPermissions
