
const initialState = {
  metabaseReports: [],
  selectedMetabaseReport: ''
}

const coachboard_metabaseReports = (state = initialState, action) => {
  switch (action.type) {
    case 'METABASE_REPORTS':
      return { ...state, metabaseReports: action.metabaseReports }
    case 'SELECT_METABASE_REPORT':
      return { ...state, selectedMetabaseReport: action.metabaseReportId }
    case 'ADD_METABASE_REPORT':
      return { ...state, metabaseReports: [ ...state.metabaseReports, action.metabaseReport ] }
    case 'UPDATE_METABASE_REPORT':
      return { ...state, metabaseReports: state.metabaseReports.map(t => t.id === action.metabaseReport.id ? action.metabaseReport : t) }
    case 'DESTROY_METABASE_REPORTS':
      return { ...state, metabaseReports: state.metabaseReports.filter(t => t.id !== action.id) }
    case 'SET_DATE_METABASE_REPORTS':
      return {
        ...state,
        [action.dateType]: action.date,
      }
    default:
      return state
  }
}

export default coachboard_metabaseReports
