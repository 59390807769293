const initialState = {
  tutorials: []
}

const coachboard_tutorials = (state = initialState, action) => {
  switch (action.type) {
    case 'TUTORIALS':
      return { ...state, tutorials: action.tutorials }
    case 'ADD_TUTORIAL':
      return { ...state, tutorials: [ action.tutorial, ...state.tutorials ] }
    case 'UPDATE_TUTORIAL':
        return { ...state, tutorials: state.tutorials.map(t => t.id === action.tutorial.id ? action.tutorial : t) }
    case 'DESTROY_TUTORIAL':
        return { ...state, tutorials: state.tutorials.filter(t => t.id !== action.id) }
    default:
      return state
  }
}

export default coachboard_tutorials
