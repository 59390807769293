import {
  COLLAPSE_SIDEBAR,
  EXPAND_SIDEBAR
} from '../actions/types'

const defaultState = {
  sideBarCollapsed: true
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case COLLAPSE_SIDEBAR:
      return { ...state, sideBarCollapsed: true }
    case EXPAND_SIDEBAR:
      return { ...state, sideBarCollapsed: false }
    default:
      return state
  }
}
