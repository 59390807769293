import {
  TEAM_WIDS,
  INACTIVE_TEAM_WIDS,
  ADD_TEAM_WID,
  DESTROY_TEAM_WID,
  ACTIVATE_TEAM_WID,
  UPDATE_TEAM_WID
} from '../actions/types'


const initialState = {
  fields: [],
  inactive: [],
  isLoaded: false,
}

export default (state = initialState, action) => {

  switch (action.type) {
    case TEAM_WIDS:
      return { ...state, fields: action.team_wids, isLoaded: true }
    case INACTIVE_TEAM_WIDS:
      return { ...state, inactive: action.team_wids }
    case ADD_TEAM_WID:
      return { ...state, fields: [...state.fields, action.team_wid] }
    case UPDATE_TEAM_WID:
      return { ...state, fields: state.fields.map(p => p.id === action.team_wid.id ? action.team_wid : p) }
    case DESTROY_TEAM_WID:
      return {
        ...state,
        fields: state.fields.filter(f => f.id !== action.team_wid.id),
        inactive: [...state.inactive, action.team_wid],
      }
    case ACTIVATE_TEAM_WID:
      return {
        ...state,
        fields: [...state.fields, action.team_wid],
        inactive: state.inactive.filter(f => f.id !== action.team_wid.id),
      }
    default:
      return state
  }
}
