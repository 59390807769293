import {
  LOGGED_IN,
  LOGGED_OUT,
  RECEIVE_ONBEHALF,
  FETCHING_AUTH,
  UPDATE_USER,
  ACTIVITIES_LOADED,
  FETCHING_CONTACT_CONVERSATION,
  SET_CONTACT_CONVERSATION,
  CONTACT_CONVERSATION_FETCH_COMPLETE,
  SET_MORE_CONTACT_CONVERSATION,
  TAG_CALL,
  SET_NEW_MESSAGE,
  SET_INCOMING_MESSAGE,
  CLEAR_COACH_CURRENT_CONTACT,
  UPDATE_TWITTER_DATA,
  UPDATE_FACEBOOK_DATA,
  UPDATE_ACTIVITY_STATUS,
  DELETE_CONTACT,
  UPDATE_TERMS_AGREEMENT,
  UPDATE_DEFAULT_SEND_METHOD,
  UPDATE_COACHES_ALL,
  UPDATE_MANAGERS_ALL,
  REFRESH_COACH_AVATAR,
  RECEIVE_ASSOCIATED_COACHES
} from '../actions/types'

const initialState = {
  isLoggedIn: false,
  isDataLoaded: false,
  coachesLoaded: false,
  coaches: [],
  associated_coaches: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        isLoggedIn: true,
        coachesLoaded: true,
        isDataLoaded: true,
        ...action.data,
      }
    case FETCHING_AUTH:
      return { ...state, isDataLoaded: false, coachesLoaded: false }
    case LOGGED_OUT:
      return { ...initialState, isDataLoaded: true, coachesLoaded: true }
    case RECEIVE_ONBEHALF:
      return {
        ...state,
        coaches: action.data,
        coachesLoaded: true,
      }
    case RECEIVE_ASSOCIATED_COACHES:
        return {
          ...state,
          associated_coaches: action.data,
        }
    case UPDATE_COACHES_ALL: {
      const coach = state.coaches_all[action.data.id]
      const updatedCoach = { ...coach, ...action.data }
      
      return {
        ...state,
        coaches_all: {
          ...state.coaches_all,
          [action.data.id]: updatedCoach,
        }
      }
    }
    case UPDATE_MANAGERS_ALL: {
      const manager = state.managers_all[action.data.id]
      const updatedManager = { ...manager, ...action.data }

      return {
        ...state,
        managers_all: {
          ...state.managers_all,
          [action.data.id]: updatedManager,
        }
      }
    }
    case REFRESH_COACH_AVATAR:
      return {...state, coaches: {
        ...state.coaches,
        [action.coachId]: {...state.coaches[action.coachId], picture: action.picture}}}
    case UPDATE_USER: {
      const coach = state.coaches[action.data.id]
      const updatedCoach = { ...coach, ...action.data }

      // update is for coach and user is coach
      if (coach && (action.data.id === state.id)) {
        return {
          ...state,
          ...action.data,
          coaches: {
            ...state.coaches,
            [action.data.id]: updatedCoach,
          },
        }
      }

      // update is for coach but user is not a coach
      if (coach) {
        return {
          ...state,
          coaches: {
            ...state.coaches,
            [action.data.id]: updatedCoach,
          },
        }
      }

      // update is not for a coach
      return { ...state, ...action.data }
    }

    case UPDATE_FACEBOOK_DATA: {
      const coach = state.coaches[action.coachId]

      const updatedCoach = {
        ...coach,
        //Here we will add any facebook information
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }
    case UPDATE_TWITTER_DATA: {
      const coach = state.coaches[action.coachId]

      const updatedCoach = {
        ...coach,
        twitter_handle: action.coach.twitter_handle,
        picture: action.coach.picture,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case ACTIVITIES_LOADED: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        activities: [...coach.activities, ...action.activities],
        activitiesPage: action.activitiesPage,
        canLoadMoreActivities: action.canLoadMore,
        activitiesLoaded: true,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case FETCHING_CONTACT_CONVERSATION: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        conversation: [],
        conversationLoaded: false,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case CONTACT_CONVERSATION_FETCH_COMPLETE: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        conversationLoaded: true,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case CLEAR_COACH_CURRENT_CONTACT: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        currentContact: {},
        conversation: [],
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case SET_CONTACT_CONVERSATION: {
      const coach = state.coaches[action.coachId]
      const manager = state.id !== action.coachId

      const updatedCoach = {
        ...coach,
        activities: coach.activities.map(
          a => a.contact.id === action.contact.id
            ? manager ? { ...a, is_read_manager: true } : { ...a, is_read: true }
            : a
          ),
        currentContact: action.contact,
        conversation: action.conversation,
        canLoadMoreConversation: action.canLoadMore,
        conversationLoaded: true,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case SET_MORE_CONTACT_CONVERSATION: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        // activities: coach.activities.map(a => a.contact.id === action.contact.id ? {...a, is_read: true } : a),
        currentContact: action.contact, // not needed?
        conversation: [...coach.conversation, ...action.conversation],
        canLoadMoreConversation: action.canLoadMore,
        conversationLoaded: true, // not needed?
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case TAG_CALL: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        conversation: coach.conversation.map(
          c => c.id === action.id
            ? { ...c, call_tag: action.tag }
            : c
        ),
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case SET_NEW_MESSAGE: {
      const coach = state.coaches[action.coachId]
      const updatedCoach = {
        ...coach,
        conversation: [...coach.conversation, action.message],
        activities: [...coach.activities, action.message],
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case UPDATE_ACTIVITY_STATUS: {
      var coach = state.coaches[action.coachId]
 
      //This is a temp fix, these issues must be resolved for good, there is some userReducers that are not working
      if ( coach === undefined) {
        coach = state.coaches[action.activity.staff.id]
        action.coachId = action.activity.staff.id
      }

      const conversation = coach.currentContact.id === action.activity.contact_id
        ? coach.conversation.map(
          a => a.id === action.activity.id
            ? { ...a, ...action.activity }
            : a
        )
        : coach.conversation

      const activities = coach.activities.map(
        a => a.id === action.activity.id
          ? { ...a, ...action.activity }
          : a
      )

      const updatedCoach = {
        ...coach,
        conversation,
        activities,
      }
      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }
    
    case DELETE_CONTACT: {
      const coaches = Object.assign(
        {},
        ...Object.keys(state.coaches).map(k => ({
          [k]: {
            ...state.coaches[k],
            activities: state.coaches[k].activities.filter(a => a.contact_id !== action.contactId),
          },
        }))
      )
      return { ...state, coaches }
    }

    case SET_INCOMING_MESSAGE: {
      const coach = state.coaches[action.coachId]
      const manager = state.kind !== 'coach'

      // if currentContact matches the message's contact, then set is_read to true
      const newMessage = coach.currentContact.id === action.message.contact.id
        ? manager ? { ...action.message, is_read_manager: true } : { ...action.message, is_read: true }
        : action.message

      // if currentContact id matches the message's contact id, then add the message to the conversation
      const conversation = coach.currentContact.id === action.message.contact.id
        ? [...coach.conversation, newMessage]
        : coach.conversation

      // add new message to activities array (relies on selector to remove duplicates)
      const activities = [...coach.activities, newMessage]

      const updatedCoach = {
        ...coach,
        conversation,
        activities,
      }

      return {
        ...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: updatedCoach,
        },
      }
    }

    case UPDATE_TERMS_AGREEMENT: {
      return { ...state, terms_agreement: true }
    }

    case UPDATE_DEFAULT_SEND_METHOD: {
      const coach = state.coaches[action.coachId]
      coach.default_send_method = action.defaultSendMethod
      return {...state,
        coaches: {
          ...state.coaches,
          [action.coachId]: coach
        }
      }
    }

    default:
      return state
  }
}
