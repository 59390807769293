import axios from 'axios'
import {
  UPDATE_COACHES_ALL,
  UPDATE_MANAGERS_ALL,
  RECEIVE_ASSOCIATED_COACHES
} from '../types'
import { setFlash, setErrorFlash } from '../flashActions'

export const addNewStaffRequest = (staff) => dispatch =>
  axios.post(`/api/v2/staffs/new_user_request`, { staff })
    .then(({data}) => {

      //Now add the new record to the correct list
      if (data.kind === 'manager') dispatch({ type: UPDATE_MANAGERS_ALL, data })
      else dispatch({ type: UPDATE_COACHES_ALL, data })

      dispatch(setFlash('New Staff Requested.', 'success'))

      return { staff_rec: data, ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

  export const getAssociatedStaffs = () => dispatch =>
    axios.get(`/api/v2/associated_staffs`)
      .then(({data}) => {
        dispatch({ type: RECEIVE_ASSOCIATED_COACHES, data: data.data.associated_staffs })
      })
      .catch(() => {
        dispatch({ type: RECEIVE_ASSOCIATED_COACHES, data: [] })
        return { ok: false }
      })

  export const getAssociatedStaffToken = (id) => dispatch => {
    const newUuid = uniqueId()
    localStorage.setItem('whistle-impersonation-nonce', newUuid)
    axios.get(`/api/v2/associated_staffs/${id}?nonce=${newUuid}`)
      .then(({data}) => {
        localStorage.setItem('whistle-impersonation-state', data.data.sso_state)
        window.open(data.data.url,"_self")
      })
      .catch(response => {
        dispatch(setErrorFlash(response))
        return { ok: false }
      })
    }

  const uniqueId = () => {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r, v;
      r = Math.random() * 16 | 0;
      v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };
