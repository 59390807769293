import {
  POSITIONS,
  ADD_POSITION,
  GENERATE_POSITIONS,
  UPDATE_POSITION,
  DESTROY_POSITION,
  INACTIVE_POSITIONS,
  ACTIVATE_POSITION,
  UPDATE_POSITION_LABEL
} from './types'

import axios from 'axios'
import { setFlash, setErrorFlash } from './flashActions'


export const loadPositions = () => dispatch =>
  axios.get(`/api/v2/positions`)
    .then(res => {
      dispatch({ type: POSITIONS, positions: res.data })
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
    })

export const loadInactivePositions = () => dispatch =>
  axios.get('/api/v2/positions/inactive')
    .then(res => {
      dispatch({ type: INACTIVE_POSITIONS, positions: res.data })
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
    })

export const createPosition = sport_position => dispatch =>
{
  return axios.post(`/api/v2/positions/`, { sport_position: {label: sport_position.label, description: sport_position.label } })
    .then(res => {
      dispatch({ type: ADD_POSITION, position: res.data })
      dispatch(setFlash('Position Created.'))
      return true
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return false
    })
}

export const generateFootballPositions =  () => dispatch =>
  axios.post(`/api/v2/positions/generate_football_positions/`)
    .then(({data}) => {
      dispatch({ type: GENERATE_POSITIONS, positions: data })
      dispatch(setFlash('Default football positions created.'))
      return { ok : true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })


export const updatePosition = sport_position => dispatch =>
  axios.put(`/api/v2/positions/${sport_position.id}`, { sport_position })
    .then(res => {
      dispatch({ type: UPDATE_POSITION, position: res.data })
      dispatch(setFlash('Position Updated.'))
      return { ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })
    
export const updatePositionLabel = (id, label) => dispatch =>
    axios.put(`/api/v2/positions/${id}/update_label`, { label })
      .then(({ data }) => {
        dispatch({ type: UPDATE_POSITION_LABEL, id, label })
        return true
      })
      .catch(err => {
        dispatch(setErrorFlash(err))
        return false
      })

export const deletePosition = id => dispatch =>
  axios.delete(`/api/v2/positions/${id}/delete`)
    .then (res => {
      dispatch({type: DESTROY_POSITION, position: res.data})
      dispatch(setFlash('Position Deleted.'))
      return { ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

export const activatePosition = id => dispatch =>
    axios.put(`/api/v2/positions/${id}/activate`)
      .then(({ data }) => {
        dispatch({ type: ACTIVATE_POSITION, position: data })
      })
      .catch(err => {
        dispatch(setErrorFlash(err))
      })
