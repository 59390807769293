// ACTIVITIES
export const LOAD_ACTIVITIES = 'LOAD_ACTIVITIES'
export const ACTIVITIES_LOADED = 'ACTIVITIES_LOADED'
export const SET_CONTACT_CONVERSATION = 'SET_CONTACT_CONVERSATION'
export const SET_MORE_CONTACT_CONVERSATION = 'SET_MORE_CONTACT_CONVERSATION'
export const FETCHING_CONTACT_CONVERSATION = 'FETCHING_CONTACT_CONVERSATION'
export const CONTACT_CONVERSATION_FETCH_COMPLETE = 'CONTACT_CONVERSATION_FETCH_COMPLETE'
export const SET_UNREAD_ACTIVITIES = 'SET_UNREAD_ACTIVITIES'

// LISTS
export const LOAD_LISTS = 'LOAD_LISTS'
export const LOADING_LISTS = 'LOADING_LISTS'
export const LOADED_LISTS = 'LOADED_LISTS'
export const LOAD_LIST_CONTACTS = 'LOAD_LIST_CONTACTS'
export const LOAD_PRIORITY_LIST = 'LOAD_PRIORITY_LIST'
export const ORDER_LISTS = 'ORDER_LISTS'
export const UPDATE_LIST = 'UPDATE_LIST'
export const DELETE_LIST = 'DELETE_LIST'
export const ADD_LIST = 'ADD_LIST'
export const SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT'
export const DRAGOVERMULTI_LIST = 'DRAGOVERMULTI_LIST'
export const DRAGOVER_LIST = 'DRAGOVER_LIST'
export const LISTS_ADD_CONTACT = 'LISTS_ADD_CONTACT'
export const LISTS_REMOVE_CONTACT = 'LISTS_REMOVE_CONTACT'
export const LISTS_REMOVE_ALL_CONTACTS = 'LISTS_REMOVE_ALL_CONTACTS'
export const LISTS_ADD_ALL_CONTACTS = 'LISTS_ADD_ALL_CONTACTS'
export const LISTS_SET_CONTACT_COLOR = 'LISTS_SET_CONTACT_COLOR'
export const FALLBACK_LISTS = 'FALLBACK_LISTS'
export const CHANGE_LISTS_VIEW = 'CHANGE_LISTS_VIEW'
export const CHANGE_LISTS_CATEGORY = 'CHANGE_LISTS_CATEGORY'
export const UPDATE_LIST_QUERY = 'UPDATE_LIST_QUERY'

//QUERIES
export const LOAD_FILTERS = 'LOAD_FILTERS'
export const LOADED_FILTERS = 'LOADED_FILTERS'
export const LOADING_FILTERS = 'LOADING_FILTERS'
export const ADD_FILTER = 'ADD_FILTER'
export const DELETE_FILTER = 'DELETE_FILTER'
export const UPDATE_FILTER = 'UPDATE_FILTER'

//PIPELINES
export const LOAD_PIPELINES = 'LOAD_PIPELINES'
export const LOAD_PIPELINE_CONTACTS = 'LOAD_PIPELINE_CONTACTS'
export const LOADED_PIPELINES = 'LOADED_PIPELINES'
export const LOADING_PIPELINES = 'LOADING_PIPELINES'
// export const LOAD_PIPELINES_STAGES = 'LOAD_PIPELINES_STAGES'
export const ADD_PIPELINE = 'ADD_PIPELINE'
export const UPDATE_PIPELINE = 'UPDATE_PIPELINE'
export const ADD_PIPELINE_STAGE = 'ADD_PIPELINE_STAGE'
export const UPDATE_PIPELINE_STAGE = 'UPDATE_PIPELINE_STAGE'
// export const LOAD_PIPELINES_RATINGS = 'LOAD_PIPELINES_RATINGS'
export const DELETE_PIPELINE_STAGE ='DELETE_PIPELINE_STAGE'
export const DELETE_PIPELINE_RATING = 'DELETE_PIPELINE_RATING'
export const ADD_PIPELINE_RATING = 'ADD_PIPELINE_RATING'
export const UPDATE_PIPELINE_RATING = 'UPDATE_PIPELINE_RATING'
export const DELETE_PIPELINE = 'DELETE_PIPELINE'
export const PIPELINE_ADD_CONTACT = 'PIPELINE_ADD_CONTACT'
export const PIPELINE_REMOVE_CONTACT = 'PIPELINE_REMOVE_CONTACT'
export const PIPELINE_REMOVE_ALL_CONTACTS = 'PIPELINE_REMOVE_ALL_CONTACTS'
export const PIPELINE_REMOVE_UNEVAL_CONTACTS = 'PIPELINE_REMOVE_UNEVAL_CONTACTS'
export const UPDATE_PIPELINE_STAGE_ORDER = 'UPDATE_PIPELINE_STAGE_ORDER'
export const UPDATE_PIPELINE_RATING_ORDER = 'UPDATE_PIPELINE_RATING_ORDER'

// STAFF_POSTS
export const LOAD_STAFF_POSTS_POSTED = 'LOAD_STAFF_POSTS_POSTED'
export const LOADING_STAFF_POSTS = 'LOADING_STAFF_POSTS'
export const LOADED_STAFF_POSTS = 'LOADED_STAFF_POSTS'
export const LOAD_STAFF_POSTS_SCHEDULED = 'LOAD_STAFF_POSTS_SCHEDULED'
export const REMOVE_SCHEDULED_POST = 'REMOVE_SCHEDULED_POST'
export const UPDATE_POSTING = 'UPDATE_POSTING'
export const CLEAR_POSTING_FORM = 'CLEAR_POSTING_FORM'
export const SET_POSTING_FORM = 'SET_POSTING_FORM'

// ANALYTICS
export const SET_ANALYTICS_CONTACTS = 'SET_ANALYTICS_CONTACTS'
export const SET_MORE_ANALYTICS_CONTACTS = 'SET_MORE_ANALYTICS_CONTACTS'
export const UPDATE_ANALYTICS_CONTACTS_QUERY = 'UPDATE_ANALYTICS_CONTACTS_QUERY'
export const LOADING_ANALYTICS_CONTACTS = 'LOADING_ANALYTICS_CONTACTS'
export const SET_CONTACT_MESSAGE_HISTORY = 'SET_CONTACT_MESSAGE_HISTORY'
export const LOADING_MESSAGE_HISTORY = 'LOADING_MESSAGE_HISTORY'
export const SET_ANALYTICS_CONVERSATION = 'SET_ANALYTICS_CONVERSATION'
export const LOADING_ANALYTICS_CONVERSATION = 'LOADING_ANALYTICS_CONVERSATION'
export const GET_CALL_TAGS = 'GET_CALL_TAGS'
export const TAG_CALL = 'TAG_CALL'

// AUTHENTICATIONS
export const GET_AUTHENTICATIONS = 'GET_AUTHENTICATIONS'
export const REMOVE_AUTHENTICATION = 'REMOVE_AUTHENTICATION'
export const LOADING_AUTHENTICATIONS = 'LOADING_AUTHENTICATIONS'
export const NOT_LOADING_AUTHENTICATIONS = 'NOT_LOADING_AUTHENTICATIONS'
export const CLEAR_AUTHENTICATIONS = 'CLEAR_AUTHENTICATIONS'

// DYNAMIC REPORTS
export const RECEIVE_REPORT_DEF = 'RECEIVE_REPORT_DEF'
export const CREATE_REPORT_DEF = 'CREATE_REPORT_DEF'
export const UPDATE_REPORT_DEF = 'UPDATE_REPORT_DEF'
export const DELETE_REPORT_DEF = 'DELETE_REPORT_DEF'
export const REPLACE_REPORT_DEF = 'REPLACE_REPORT_DEF'
export const RECEIVE_REPORT_DEF_OPTIONS = 'RECEIVE_REPORT_DEF_OPTIONS'
export const RECEIVE_REPORT_DEF_COLUMNS = 'RECEIVE_REPORT_DEF_COLUMNS'

// FLASH
export const SET_FLASH = 'SET_FLASH'
export const CLEAR_FLASH = 'CLEAR_FLASH'
export const SET_LOADING_FLASH = 'SET_LOADING_FLASH'
export const CLEAR_LOADING_FLASH = 'CLEAR_LOADING_FLASH'

// EVENTS 
export const LOAD_GAME_INFO = 'LOAD_GAME_INFO'
export const LOAD_GAME_INFO_START = 'LOAD_GAME_INFO_START'
export const LOAD_GAME_INFO_END = 'LOAD_GAME_INFO_END'

// IMPORT DATA
export const GET_IMPORT_RESULTS = 'GET_IMPORT_RESULTS'
export const BEGIN_IMPORT = 'BEGIN_IMPORT'
export const IMPORT_ERROR = 'IMPORT_ERROR'

// MESSENGER
export const ADD_TO_RECIPIENTS = 'ADD_TO_RECIPIENTS'
export const ADD_ALL_TO_RECIPIENTS = 'ADD_ALL_TO_RECIPIENTS'
export const REMOVE_FROM_RECIPIENTS = 'REMOVE_FROM_RECIPIENTS'
export const REMOVE_ALL_FROM_RECIPIENTS = 'REMOVE_ALL_FROM_RECIPIENTS'
export const REMOVE_SET_FROM_RECIPIENTS = 'REMOVE_SET_FROM_RECIPIENTS'

// MASSMESSENGER FORM
export const CLEAR_MM_FORM = 'CLEAR_MM_FORM'
export const UPDATE_MM_FORM = 'UPDATE_MM_FORM'
export const RECEIVE_MESSAGE_PREVIEW = 'RECEIVE_MESSAGE_PREVIEW'
export const SET_MM_FORM_FROM_PREVIEW = 'SET_MM_FORM_FROM_PREVIEW'
export const APPEND_LIST_TO_MM = 'APPEND_LIST_TO_MM'
export const LOADING_MESSAGE_PREVIEW = 'LOADING_MESSAGE_PREVIEW'
export const DONE_LOADING_MESSAGE_PREVIEW = 'DONE_LOADING_MESSAGE_PREVIEW'
export const CLEAR_MM_PREVIEW = 'CLEAR_MM_PREVIEW'
export const FETCHING_SENDER_FROM_CONTACT = 'FETCHING_SENDER_FROM_CONTACT'
export const FETCHING_SENDER_FROM_CONTACT_DONE = 'FETCHING_SENDER_FROM_CONTACT_DONE'

// MESSAGES
export const SET_NEW_MESSAGE = 'SET_NEW_MESSAGE'
export const SET_INCOMING_MESSAGE = 'SET_INCOMING_MESSAGE'
export const UPDATE_ACTIVITY_STATUS = 'UPDATE_ACTIVITY_STATUS'
export const SELF_SENDER = 'self_sender'
export const PRIMARY_SENDER = 'primary_sender'
export const SECONDARY_SENDER = 'secondary_sender'
export const AREA_RECRUITING_COACH = 'area_recruiting_coach'
export const POSITION_RECRUITING_COACH = 'position_recruiting_coach'

// META
export const SET_VERSION = 'SET_VERSION'
export const NOOP = 'NOOP'

// MISC
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR'

// CURRENT CONTACT
export const TOGGLE_FROZEN = 'TOGGLE_FROZEN'
export const TOGGLE_PRIORITY = 'TOGGLE_PRIORITY'
export const CONTACT_UPDATED = 'CONTACT_UPDATED'
export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT'
export const FETCHING_CURRENT_CONTACT = 'FETCHING_CURRENT_CONTACT'
export const FETCHING_CURRENT_CONTACT_FAILURE = 'FETCHING_CURRENT_CONTACT_FAILURE'
export const TOGGLE_SEND_METHOD = 'TOGGLE_SEND_METHOD'
export const CHANGE_CONVERSATION_FILTER = 'CHANGE_CONVERSATION_FILTER'
export const CLEAR_CURRENT_CONTACT = 'CLEAR_CURRENT_CONTACT'

export const FETCHING_CURRENT_CONTACT_LISTS = 'FETCHING_CURRENT_CONTACT_LISTS'
export const SET_CURRENT_CONTACT_LISTS = 'SET_CURRENT_CONTACT_LISTS'
export const FETCHING_CURRENT_CONTACT_ADD_LISTS = 'FETCHING_CURRENT_CONTACT_ADD_LISTS'
export const SET_CURRENT_CONTACT_ADD_LISTS = 'SET_CURRENT_CONTACT_ADD_LISTS'
export const ADD_TO_CURRENT_CONTACT_LIST = 'ADD_TO_CURRENT_CONTACT_LIST'
export const REMOVE_FROM_CURRENT_CONTACT_LIST = 'REMOVE_FROM_CURRENT_CONTACT_LIST'
export const SET_CURRENT_CONTACT_RELATIONSHIPS = 'SET_CURRENT_CONTACT_RELATIONSHIPS'
export const DELETE_CURRENT_CONTACT_RELATIONSHIP = 'DELETE_CURRENT_CONTACT_RELATIONSHIP'
export const FETCHING_CURRENT_CONTACT_RELATIONSHIPS = 'FETCHING_CURRENT_CONTACT_RELATIONSHIPS'
export const APPEND_TO_CURRENT_CONTACT_RELATIONSHIP = 'APPEND_TO_CURRENT_CONTACT_RELATIONSHIP'
export const FETCHING_CURRENT_CONTACT_PIPELINE_EVALS = 'FETCHING_CURRENT_CONTACT_PIPELINE_EVALS'
export const PIPELINE_EVALS = 'PIPELINE_EVALS'
export const UPDATE_PIPELINE_EVAL = 'UPDATE_PIPELINE_EVAL'
export const ADD_CURRENT_CONTACT_TO_PIPELINE = 'ADD_CURRENT_CONTACT_TO_PIPELINE'
export const UPDATE_AREA_POSITION_COACH = 'UPDATE_AREA_POSITION_COACH'

// CONTACTS v2
export const LOAD_CONTACTS_REQUEST = 'LOAD_CONTACTS_REQUEST'
export const LOAD_CONTACTS_SUCCESS = 'LOAD_CONTACTS_SUCCESS'
export const LOAD_CONTACTS_FAILURE = 'LOAD_CONTACTS_FAILURE'
export const LOAD_NEW_CONTACT = 'LOAD_NEW_CONTACT'
export const LOAD_UPDATED_CONTACT = 'LOAD_UPDATED_CONTACT'
export const DESTROY_ALL_CONTACTS = 'DESTROY_ALL_CONTACTS'
export const EXPORTING_CONTACTS_REQUEST = 'EXPORTING_CONTACTS_REQUEST'
export const EXPORTING_CONTACTS_COMPLETE = 'EXPORTING_CONTACTS_COMPLETE'
export const LOAD_UPDATED_CONTACT_FOLLOWING = 'LOAD_UPDATED_CONTACT_FOLLOWING'

// SCHOOL
export const GET_SCHOOL = 'GET_SCHOOL'
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS'
export const GET_SCHOOL_FAILURE = 'GET_SCHOOL_FAILURE'

// SCHEDULED_MESSAGES
export const SET_SCHEDULED = 'SET_SCHEDULED'
export const REMOVE_SCHEDULED = 'REMOVE_SCHEDULED'
export const RESET_SCHEDULED = 'RESET_SCHEDULED'
export const SET_SCHEDULED_LOADED = 'SET_SCHEDULED_LOADED'

// USER
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const LOGGED_OUT = 'LOGGED_OUT'
export const LOGGED_IN = 'LOGGED_IN'
export const UPDATE_AUTH = 'UPDATE_AUTH'
export const FETCHING_AUTH = 'FETCHING_AUTH'
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const RECEIVE_ONBEHALF = 'RECEIVE_ONBEHALF'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_TWITTER_DATA = 'UPDATE_TWITTER_DATA'
export const UPDATE_FACEBOOK_DATA = 'UPDATE_FACEBOOK_DATA'
export const CLEAR_COACH_CURRENT_CONTACT = 'CLEAR_COACH_CURRENT_CONTACT'
export const UPDATE_TERMS_AGREEMENT = 'UPDATE_TERMS_AGREEMENT'
export const UPDATE_DEFAULT_SEND_METHOD = 'UPDATE_DEFAULT_SEND_METHOD'
export const UPDATE_COACHES_ALL = 'UPDATE_COACHES_ALL'
export const UPDATE_MANAGERS_ALL = 'UPDATE_MANAGERS_ALL'
export const REFRESH_COACH_AVATAR = 'REFRESH_COACH_AVATAR'
export const RECEIVE_ASSOCIATED_COACHES = 'RECEIVE_ASSOCIATED_COACHES'

// COACH MAINTENANCE
export const REPLACE_COACH_FIELDS = 'REPLACE_COACH_FIELDS'
export const RECEIVE_COACH_FIELDS = 'RECEIVE_COACH_FIELDS'

// WID FIELDS
export const RECEIVE_WID_FIELDS = 'RECEIVE_WID_FIELDS'
export const RECEIVE_INACTIVE_WID_FIELDS = 'RECEIVE_INACTIVE_WID_FIELDS'
export const CREATE_WID_FIELD = 'CREATE_WID_FIELD'
export const UPDATE_WID_PIC = 'UPDATE_WID_PIC'
export const REMOVE_WID_PIC = 'REMOVE_WID_PIC'
export const REPLACE_WID_FIELDS = 'REPLACE_WID_FIELDS'
export const UPDATE_WID_LABEL = 'UPDATE_WID_LABEL'
export const DELETE_WID = 'DELETE_WID'
export const ACTIVATE_WID = 'ACTIVATE_WID'
export const UPDATE_WID_GAMES = 'UPDATE_WID_GAMES'
export const RECEIVE_WID_FUNCTIONS = 'RECEIVE_WID_FUNCTIONS'

// QUICK LISTS
export const SELECT_QUICK_LIST = 'SELECT_QUICK_LIST'
export const LOADED_QUICK_LIST = 'LOADED_QUICK_LIST'
export const REMOVE_QUICK_LIST = 'REMOVE_QUICK_LIST'
export const RESET_QUICK_LISTS = 'RESET_QUICK_LISTS'

// TUTORIALS
export const TUTORIALS = 'TUTORIALS'
export const ADD_TUTORIAL = 'ADD_TUTORIAL'
export const UPDATE_TUTORIAL = 'UPDATE_TUTORIAL'
export const DESTROY_TUTORIAL = 'DESTROY_TUTORIAL'

// CONTEXT MENU
export const OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU'
export const CLOSE_CONTEXT_MENU = 'CLOSE_CONTEXT_MENU'
export const SET_CONTEXT_MENU_ACTIONS = 'SET_CONTEXT_MENU_ACTIONS'

// SPORTS POSITIONS
export const POSITIONS = 'POSITIONS'
export const ADD_POSITION = 'ADD_POSITION'
export const GENERATE_POSITIONS = 'GENERATE_POSITIONS'
export const UPDATE_POSITION = 'UPDATE_POSITION'
export const DESTROY_POSITION = 'DESTROY_POSITION'
export const INACTIVE_POSITIONS = 'INACTIVE_POSITIONS'
export const ACTIVATE_POSITION = 'ACTIVATE_POSITION'
export const UPDATE_POSITION_LABEL = 'UPDATE_POSITION_LABEL'

// SIDEKIQ SCHEDULES
export const GET_SIDEKIQ_SCHEDULES = 'GET_SIDEKIQ_SCHEDULES'
export const FETCH_SIDEKIQ_SCHEDULES = 'FETCH_SIDEKIQ_SCHEDULES'
export const FETCH_SIDEKIQ_SCHEDULES_COMPLETE = 'FETCH_SIDEKIQ_SCHEDULES_COMPLETE'
export const UPDATE_SIDEKIQ_SCHEDULE = 'UPDATE_SIDEKIQ_SCHEDULE'

// TEAM WHISTLE IDS
export const TEAM_WIDS = 'TEAM_WIDS'
export const INACTIVE_TEAM_WIDS = 'INACTIVE_TEAM_WIDS'
export const ADD_TEAM_WID = 'ADD_TEAM_WID'
export const UPDATE_TEAM_WID = 'UPDATE_TEAM_WID'
export const DESTROY_TEAM_WID = 'DESTROY_TEAM_WID'
export const ACTIVATE_TEAM_WID = 'ACTIVATE_TEAM_WID'

// TEAM INFO
export const LOAD_TEAM_INFO = 'LOAD_TEAM_INFO'
export const UPDATE_TEAM_INFO = 'UPDATE_TEAM_INFO'
