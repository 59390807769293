
const initialState = {
  global_setting: {
    compression_quality_options: {options: []}
  },
  timezone: []
}

const coachboard_global_settings = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_GLOBAL_SETTINGS':
      return { ...state, global_setting: action.global_setting }
    case 'UPDATE_GLOBAL_SETTING':
        return { ...state, global_setting: action.global_setting }
    case 'GET_TIME_ZONE':
      return { ...state, timezone: action.timezone }
    default:
      return state
  }
}

export default coachboard_global_settings
