import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'

const initialState = {
  staff: [],
  ipLocations: {},
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
  loading: false,
}

const coachboard_staffs = (state = initialState, action) => {
  const { staff } = state
  switch (action.type) {
    case 'GET_STAFFS':
      return { ...state, staff: action.staffs}
    case 'FETCH_STAFFS':
      return { ...state, loading: true }
    case 'FETCH_STAFFS_COMPLETE':
      return { ...state, loading: false }
    case 'ADD STAFF':
      return { ...state, staff: [ ...state.staff, action.staff ] }
    case 'UPDATE STAFF':
      return {
        ...state,
        staff: state.staff.map(s => s.id === action.staff.id ? action.staff : s),
      }
    case 'DESTROY STAFF':
      // get staff index that was deleted
      const deletedStaffIndex = staff.findIndex(({ id }) => id === action.id)
      // add deleted_at to deleted staff
      // get actual deleted_at value from server
      const deletedStaff = {
        ...staff[deletedStaffIndex],
        deleted_at: new Date().toISOString(),
      }
      // replace previous staff state with updated version
      return {
        ...state,
        staff: [
          ...staff.slice(0, deletedStaffIndex),
          deletedStaff,
          ...staff.slice(deletedStaffIndex + 1),
        ],
      }
    case 'RESET_STAFFS':
      return {
        ...state,
        staff: [],
      }
    case 'SET_IP_LOCATIONS':
      return { ...state, ipLocations: action.ipLocations }
    case 'SET_DATE_STAFF':
      return {
        ...state,
        [action.dateType]: action.date,
      }
    case 'REACTIVATE_STAFF':
      const updatedStaffIndex = staff.findIndex(({ id }) => id === action.id)
      const updatedStaff = {
        ...staff[updatedStaffIndex],
        deleted_at: null,
      }
      return {
        ...state,
        staff: [
          ...staff.slice(0, updatedStaffIndex),
          updatedStaff,
          ...staff.slice(updatedStaffIndex + 1),
        ],
      }
    default:
      return state
  }
}

export default coachboard_staffs
