import { combineReducers } from 'redux'
import analytics from './analyticsReducer'
import authentications from './authenticationsReducer'
import contacts from './contactsReducer'
import teamLists from './teamListsReducer'
import teamPipelines from './teamPipelinesReducer'
import staffPosts from './staffPostsReducer'
import contextMenu from './contextMenuReducer'
import currentContact from './currentContactReducer'
import flash from './flashReducer'
import loadingFlash from './loadingFlashReducer'
import importData from './importDataReducer'
import massMessengerForm from './massMessengerFormReducer'
import messenger from './messengerReducer'
import misc from './miscReducer'
import reports from './reportsReducer'
import scheduledMessages from './scheduledMessagesReducer'
import quickLists from './quickListsReducer'
import user from './userReducer'
import reportDefs from './reportDefReducer'
import coachboard_teams from './coachboard/coachboard_teams'
import coachboard_metabaseReports from './coachboard/coachboard_metabaseReports'
import coachboard_institutes from './coachboard/coachboard_institutes'
import coachboard_staffs from './coachboard/coachboard_staffs'
import coachboard_team_import_integrations from './coachboard/coachboard_team_import_integrations'
import coachboard_import_integrations from './coachboard/coachboard_import_integrations'
import coachboard_global_settings from './coachboard/coachboard_global_settings'
import coachboard_positions from './coachboard/coachboard_positions'
import coachboard_sidekiq_schedules from './coachboard/coachboard_sidekiq_schedules'
import coachboard_permissions from './coachboard/coachboard_permissions'
import coachboard_contacts from './coachboard/coachboard_contacts'
import coachboard_reports from './coachboard/coachboard_reports'
import coachboard_analytics from './coachboard/coachboard_analytics'
import coachboard_tutorials from './coachboard/coachboard_tutorials'
import coachboard_broadcasts from './coachboard/coachboard_broadcasts'
import widFields from './wIDFieldsReducer'
import coaches_for_maint from './staffReducer'
import coachboard_appPermissions from './coachboard/coachboard_appPermissions'
import positions from './sportPositionsReducer'
import team from './teamReducer'
import team_wids from './teamWidsReducer'
import teamFilters from './teamFiltersReducer'
import timelineEvents from './timelineEventsReducer'

const appReducer = combineReducers({
  analytics,
  authentications,
  contacts,
  contextMenu,
  currentContact,
  importData,
  messenger,
  massMessengerForm,
  misc,
  quickLists,
  reports,
  scheduledMessages,
  user,
  reportDefs,
  flash,
  loadingFlash,
  team,
  widFields,
  positions,
  team_wids,
  timelineEvents,
  //COACHBOARD REDUCERS
  coachboard_teams,
  coachboard_institutes,
  coachboard_staffs,
  coachboard_positions,
  coachboard_sidekiq_schedules,
  coachboard_permissions,
  coachboard_contacts,
  coachboard_reports,
  coachboard_analytics,
  coachboard_tutorials,
  coachboard_broadcasts,
  coachboard_metabaseReports,
  coachboard_appPermissions,
  coachboard_team_import_integrations,
  coachboard_import_integrations,
  coachboard_global_settings,
  //COACH MAINTENANCE
  coaches_for_maint,
  teamLists,
  teamPipelines,
  teamFilters,
  staffPosts
})

const rootReducer = (state, action) => {
  if (action.type === 'UNAUTH_USER') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;
