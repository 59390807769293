const initialState = {
  managers: [],
  coaches: [],
  selectedManagerId: ''
}

export default (state=initialState, action) => {
  switch (action.type){
    case 'GET_PERMISSIONS_MANAGERS':
      return { ...state, managers: action.managers, coaches: [], selectedManagerId: '' }
    case 'GET_PERMISSIONS_COACHES':
      return { ...state, coaches: action.coaches, selectedManagerId: action.managerId }
    case 'ADD_ASSIGNMENT':
      return { ...state, coaches: state.coaches.map(c => c.id === action.coachId ? { ...c, assigned: true } : c) }
    case 'REMOVE_ASSIGNMENT':
      return { ...state, coaches: state.coaches.map(c => c.id === action.coachId ? { ...c, assigned: false } : c) }
    default:
      return state
  }
}
