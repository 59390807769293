import {
  SELECT_QUICK_LIST,
  REMOVE_QUICK_LIST,
  RESET_QUICK_LISTS
} from '../actions/types'

export default (state = [], action) => {
  switch (action.type) {
    case SELECT_QUICK_LIST:
      return [...state, {id: action.id}]
    case REMOVE_QUICK_LIST:
      return state.filter(l => l.id !== action.id)
    case RESET_QUICK_LISTS:
      return []
    default:
      return state
  }
}
