import axios from 'axios'
import {
  LOAD_PIPELINES,
  LOAD_PIPELINE_CONTACTS,
  LOADING_PIPELINES,
  LOADED_PIPELINES,
  UPDATE_PIPELINE,
  ADD_PIPELINE,
  ADD_PIPELINE_STAGE,
  ADD_PIPELINE_RATING,
  UPDATE_PIPELINE_STAGE,
  UPDATE_PIPELINE_STAGE_ORDER,
  UPDATE_PIPELINE_RATING,
  UPDATE_PIPELINE_RATING_ORDER,
  DELETE_PIPELINE,
  DELETE_PIPELINE_STAGE,
  DELETE_PIPELINE_RATING,
  PIPELINE_ADD_CONTACT,
  PIPELINE_REMOVE_CONTACT,
  PIPELINE_REMOVE_ALL_CONTACTS,
  PIPELINE_REMOVE_UNEVAL_CONTACTS,
} from '../types'
import { setErrorFlash, setFlash } from '../flashActions'

export const removeAllContactFromPipeline =  (pipelineId, coachId, unevaluatedOnly=false) => dispatch => {
    return axios.patch(`/api/v2/pipelines/${pipelineId}/contacts/delete_all`,
      {unevaluated_only: unevaluatedOnly}, { headers: { onbehalf: coachId } })
        .then(({data}) => {
          if (unevaluatedOnly) dispatch({ type: PIPELINE_REMOVE_UNEVAL_CONTACTS, pipelineId: pipelineId, contactIds: data.data.contact_ids })
          else dispatch({ type: PIPELINE_REMOVE_ALL_CONTACTS, pipelineId: pipelineId })
        })
        .catch(err => {
          dispatch(setErrorFlash(err))
        })
 }

export const removeContactFromPipeline =  (pipelineId, contactId, coachId) => dispatch => {
  return axios.delete(`/api/v2/pipelines/${pipelineId}/contacts/${contactId}`,
    { headers: { onbehalf: coachId } })
      .then (() => {
        dispatch({ type: PIPELINE_REMOVE_CONTACT, pipelineId: pipelineId, contactId: contactId })
      })
      .catch(err => {
        dispatch(setErrorFlash(err))
      })
}



export const addContactToPipeline =  ({pipelineId, contactIds, coachId, areaCoach, positionCoach, overrideMetadata}) => dispatch => {
  return axios.post(`/api/v2/pipeline_contacts`, {pipeline_contact: {pipeline_id: pipelineId, contact_ids: contactIds, area_recruiting_coach: areaCoach, position_recruiting_coach: positionCoach, override_metadata: overrideMetadata}}, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: PIPELINE_ADD_CONTACT, pipelineId: pipelineId, pipeline_contacts: data.data.pipeline_contacts })
    })
    .catch( err => {
      dispatch(setErrorFlash(err))
    })
 }
 
 export const addAllContactsToPipeline = ( {pipelineId, coachId, filter, lists, relationships, exactSearch, prioritySearch, freezedSearch, areaCoach, positionCoach, overrideMetadata} )  => dispatch => {
  let filterEncoded = encodeURIComponent(JSON.stringify(filter))
  let listsEncoded = encodeURIComponent(JSON.stringify(lists))
  let sortEncoded = encodeURIComponent(JSON.stringify([]))
  let relationshipsEncoded = encodeURIComponent(JSON.stringify(relationships))

  return axios.get(`/api/v2/contacts?filter=${filterEncoded}&sort=${sortEncoded}&lists=${listsEncoded}&relationships=${relationshipsEncoded}&exactsearch=${exactSearch}&prioritysearch=${prioritySearch}&freezedSearch=${freezedSearch}&all=true&pipelineId=${pipelineId}&idsOnly=true`)
    .then(({data: { contact_ids }}) => {
      dispatch(addContactToPipeline({pipelineId: pipelineId, contactIds: contact_ids.join(","), coachId: coachId, areaCoach: areaCoach, positionCoach: positionCoach, overrideMetadata: overrideMetadata}))
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
    })
 }

export const loadPipelines = () => async dispatch => {
  dispatch({ type: LOADING_PIPELINES })
  return axios.get(`/api/v2/pipelines`)
    .then(({ data }) => {
      dispatch({ type: LOAD_PIPELINES, pipelines: data.data.pipelines })
      dispatch({ type: LOADED_PIPELINES })
    })
    .catch(err => {
      dispatch({ type: LOADED_PIPELINES })
      dispatch(setErrorFlash(err))
    })
}

export const createPipeline = (pipelineName, teamId, coachId) => dispatch => {
  dispatch({ type: LOADING_PIPELINES })
  return axios.post(`/api/v2/pipelines/`, { pipeline: { name: pipelineName, team_id: teamId } }, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: ADD_PIPELINE, pipeline: data.data })
      dispatch({ type: LOADED_PIPELINES })
      dispatch(setFlash('Pipeline has been created.', 'success'))
      return ( { data: data, ok: true } )
    })
    .catch(res => {
      dispatch({ type: LOADED_PIPELINES })
      dispatch(setErrorFlash(res))
      return ( { ok: false } )
    })
}

export const updatePipeline = (pipeline, coachId) => dispatch => {
  dispatch({ type: LOADING_PIPELINES})
  return axios.put(`/api/v2/pipelines/${pipeline.id}`, { pipeline: pipeline}, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: UPDATE_PIPELINE, pipeline: data.data })
      dispatch({ type: LOADED_PIPELINES})
      dispatch(setFlash('Pipeline has been updated.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      dispatch({ type: LOADED_PIPELINES})
      return ( { ok: false } )
    })
}

export const deleteSelectedPipeline = (selectedPipeline, coachId) => dispatch => {
  dispatch({ type: LOADING_PIPELINES })
  return axios.delete(`/api/v2/pipelines/${selectedPipeline.id}`, { headers: { onbehalf: coachId } })
    .then(res => {
      dispatch({ type: DELETE_PIPELINE, pipeline: selectedPipeline })
      dispatch({ type: LOADED_PIPELINES })
      dispatch(setFlash('Pipeline deleted.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch({ type: LOADED_PIPELINES })
      dispatch(setErrorFlash(err))
      return ( { ok: false })
    })
}

export const createPipelineStage = (pipelineStage, coachId) => dispatch => {
  return axios.post(`/api/v2/pipeline_stages/`, { pipeline_stage: pipelineStage }, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: ADD_PIPELINE_STAGE, stage: data.data })
      dispatch(setFlash('Stage has been added.', 'success'))
      return ( { ok: true } )
    })
    .catch(res => {
      dispatch(setErrorFlash(res))
      return ( { ok: false } )
    })
}

export const updatePipelineStage = (stageId, selectedStage, coachId) => dispatch => {
  return axios.put(`/api/v2/pipeline_stages/${stageId}`, { pipeline_stage: selectedStage}, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: UPDATE_PIPELINE_STAGE, stage: data.data })
      dispatch(setFlash('Stage has been updated.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return ( { ok: false } )
    })
}

export const updatePipelineStageOrder = (pipeline_id, ordered_pipeline_stages, fallbackFields) => dispatch => {
  dispatch({ type: UPDATE_PIPELINE_STAGE_ORDER, pipeline_stages: ordered_pipeline_stages })
  return axios.put(`/api/v2/pipeline_stages/update_order`,{ pipeline_id: pipeline_id, pipeline_stage_ids: ordered_pipeline_stages.map(s => s.id).join(',') })
    .then(({ data }) => {
      return { ok: true }
    })
    .catch(err => {
      dispatch({ type: UPDATE_PIPELINE_STAGE_ORDER, pipeline_stages: fallbackFields })
      dispatch(setErrorFlash(err))
      return { ok: false }
    })
}

export const deleteSelectedPipelineStage =  (selectedStage, coachId) => dispatch => {
  return axios.delete(`/api/v2/pipeline_stages/${selectedStage.id}`, { headers: { onbehalf: coachId } })
    .then (res => {
      dispatch({ type: DELETE_PIPELINE_STAGE, stage: selectedStage })
      dispatch(setFlash('Stage has been deleted.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return ( { ok: false } )
    })
}

export const createPipelineRating = (pipelineRating, coachId) => dispatch => {
  return axios.post(`/api/v2/pipeline_ratings/`, { pipeline_rating: pipelineRating }, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: ADD_PIPELINE_RATING, rating: data.data })
      dispatch(setFlash('Rating has been added.', 'success'))
      return ( { ok: true } )
    })
    .catch(res => {
      dispatch(setErrorFlash(res))
      return ( { ok: false } )
    })
}

export const deleteSelectedPipelineRating =  (selectedRating, coachId) => dispatch => {
  return axios.delete(`/api/v2/pipeline_ratings/${selectedRating.id}`, { headers: { onbehalf: coachId } })
    .then (res => {
      dispatch({ type: DELETE_PIPELINE_RATING, rating: selectedRating })
      dispatch(setFlash('Rating has been deleted.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return ( { ok: false } )
    })
}

export const updatePipelineRating = (selectedRating, coachId) => dispatch => {
  return axios.put(`/api/v2/pipeline_ratings/${selectedRating.id}`, { pipeline_rating: selectedRating}, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      dispatch({ type: UPDATE_PIPELINE_RATING, rating: data.data })
      dispatch(setFlash('Rating has been updated.', 'success'))
      return ( { ok: true } )
    })
    .catch(err => {
      dispatch(setErrorFlash(err))
      return ( { ok: false } )
    })
}

export const updatePipelineRatingOrder = (pipeline_id, ordered_pipeline_ratings, fallbackFields) => dispatch => {
  dispatch({ type: UPDATE_PIPELINE_RATING_ORDER, pipeline_ratings: ordered_pipeline_ratings })
  return axios.put(`/api/v2/pipeline_ratings/update_order`,{ pipeline_id: pipeline_id, pipeline_rating_ids: ordered_pipeline_ratings.map(s => s.id).join(',') })
  .then(({ data }) => {
    return { ok: true }
  })
  .catch(err => {
    dispatch({ type: UPDATE_PIPELINE_RATING_ORDER, pipeline_ratings: fallbackFields })
    dispatch(setErrorFlash(err))
    return { ok: false }
  })
}

export const fetchPipelineAllContacts = (pipelineId, coachId) => dispatch => {
  return axios.get(`/api/v2/pipeline_contacts?pipeline_id=${pipelineId}`, { headers: { onbehalf: coachId } })
     .then(({ data }) => {
        dispatch({ type: LOAD_PIPELINE_CONTACTS, pipelineId: pipelineId, pipeline_contacts: data.data.pipeline_contacts})
        return { ok: true }
     })
     .catch(err => {
       dispatch(setErrorFlash(err))
       return { ok: false }
     }) 
}
