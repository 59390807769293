import axios from 'axios'
import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum';
import { validateAuth, logout } from '../actions/userActions'
import { FlashProvider } from '../reference/contextUtils'
import Flash from '../components/flash/Flash'
import LoadingFlash from '../components/flash/LoadingFlash'
import NewFlash from '../components/flash/NewFlash'
import Loader from '../components/shared/Loader'
import ContextMenu from '../components/shared/ContextMenu'
import './App.css'
import 'react-image-lightbox/style.css';

const UnauthRoutes = React.lazy(() => import('./UnauthRoutes'))
const ManagerRoutes = React.lazy(() => import('./ManagerRoutes'))
const CoachRoutes = React.lazy(() => import('./CoachRoutes'))
const AdminRoutes = React.lazy(() => import('./AdminRoutes'))
const OnboardingRoutes = React.lazy(() => import('./OnboardingRoutes'))
const ComplianceRoutes = React.lazy(() => import('./ComplianceRoutes'))

export const BaseUrlContext = React.createContext('/')

class App extends Component {
  componentDidMount() {

    // Add a request interceptor to update the access-token
    // in the axios header since it changes for each validate
    axios.interceptors.request.use(function (config) {
      //If the token is available then updated the token
      if (localStorage.getItem('access-token')) {
        const token = localStorage.getItem('access-token')
        const expiry = localStorage.getItem('expiry')
        config.headers['access-token'] =  token;
        config.headers['expiry'] =  expiry;
      }
      return config;
    });

    if (localStorage.getItem('access-token')) {
      this.props.validateAuth()
    } else {
      this.props.logout()
    }

    // Attach Datadog Real User Monitoring (RUM) if not running in dev
    const environment = process.env.REACT_APP_ENV
    if (environment !== 'dev') {
      datadogRum.init({
        applicationId: '90d3b5c5-5a06-4613-9f82-147f377d1e8c',
        clientToken: 'pubd9f38a5b3a040629412095d41f2f669b',
        site: 'datadoghq.com',
        service:'Whistle-Web',
        env: environment,
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel:'mask-user-input'
      });
            
      datadogRum.startSessionReplayRecording();
    }

  }

  trafficController = () => {
    if (this.props.isLoggedIn) {
      switch (this.props.userKind) {
        case 'coach':
          if (this.props.isOnboarding) return <OnboardingRoutes coachId={this.props.userId} location={this.props.location}/>
          else return <CoachRoutes coachId={this.props.userId} location={this.props.location}/>
        case 'manager':
          if (this.props.isOnboarding) return <OnboardingRoutes location={this.props.location}/>
          else return <ManagerRoutes location={this.props.location}/>
        case 'admin':
        case 'contentadmin':
          return <AdminRoutes location={this.props.location}/>
        case 'compliance':
          return <ComplianceRoutes location={this.props.location}/>
        default:
          return <UnauthRoutes/>
      }
    } else {
      return <UnauthRoutes/>
    }
  }

  render() {
    return (
      <div className="container">
        <FlashProvider>
          <Suspense fallback={<Loader containerClass="app-loader"/>}>
            {this.props.isFetching
              ? <Loader containerClass="app-loader"/>
              : this.trafficController()}
          </Suspense>
          <NewFlash/>
          <LoadingFlash/>
        </FlashProvider>
        <Flash/>
        <ContextMenu/>
      </div>
    )
  }
}

App.propTypes = {
  validateAuth: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  userKind: PropTypes.string,
  userId: PropTypes.number,
}

// isFetching includes coachesLoaded because we're using a single selector to get the active coach, rather than using the url parameter. Therefore the coaches must be loaded before the active coach can be determined and subsequent api calls can be made with the active coach id.
const mapStateToProps = (state, props) => ({
  isLoggedIn: state.user.isLoggedIn,
  isOnboarding: state.user.is_onboarding,
  userKind: state.user.kind,
  isFetching: !state.user.isDataLoaded || !state.user.coachesLoaded,
  userId: state.user.id,
})

export default connect(mapStateToProps, { validateAuth, logout })(App)
