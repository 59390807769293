import { createStore, applyMiddleware, compose } from 'redux'
import axios from 'axios'
// import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import apiMiddleware from 'redux-devise-axios'
// import createHistory from 'history/createBrowserHistory'
import rootReducer from './reducers'

const options = { axios, logoutAction: 'LOGGED_OUT' }

// export const history = createHistory()

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  apiMiddleware(options),
  // routerMiddleware(history)
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
)

export default store
