import {
  LOAD_LISTS,
  LOADED_LISTS,
  LOADING_LISTS,
  LOAD_PRIORITY_LIST,
  ORDER_LISTS,
  LOAD_LIST_CONTACTS,
  UPDATE_LIST,
  DELETE_LIST,
  ADD_LIST,
  SET_ACTIVE_CONTACT,
  DRAGOVER_LIST,
  DRAGOVERMULTI_LIST,
  LISTS_ADD_CONTACT,
  LISTS_REMOVE_CONTACT,
  LISTS_REMOVE_ALL_CONTACTS,
  LISTS_ADD_ALL_CONTACTS,
  LISTS_SET_CONTACT_COLOR,
  FALLBACK_LISTS,
  CHANGE_LISTS_VIEW,
  CHANGE_LISTS_CATEGORY,
  UPDATE_LIST_QUERY
} from '../actions/types'

import sortBy from 'ramda/src/sortBy'
import prop from 'ramda/src/prop'

const initialState = {
  lists: [],
  priorityList: [],
  loading: true,
  activeContact: null,
  fallbackLists: [],
  viewType: 'COMPACT',
  viewTypes : [{label:'COMPACT', value: 'COMPACT'},{label:'DETAIL', value: 'DETAIL'}],
  listCategory: 'General'
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_LISTS:
      return { ...state, lists: action.lists }
    case CHANGE_LISTS_VIEW:
      return { ...state, viewType: action.viewType }
    case CHANGE_LISTS_CATEGORY:
      return { ...state, listCategory: action.listCategory }
    case FALLBACK_LISTS:
      return { ...state, lists: action.fallbackLists, fallbackLists: [], activeContact: null }
    case LOAD_PRIORITY_LIST:
      return { ...state, priorityList: {id: 0, name:'Priority Contacts', contacts: action.contacts} }
    case LOADING_LISTS:
      return { ...state, loading: true }
    case LOADED_LISTS:
      return { ...state, loading: false }
    case ORDER_LISTS:
      return { ...state, lists: state.lists.map(l => action.lists.find(ol => ol.id === l.id) ? action.lists.find(ol => ol.id === l.id) : l)}
    case LOAD_LIST_CONTACTS:
      return { ...state, lists: state.lists.map(l => l.id == action.listId
                                  ? l.kind == 'static'
                                        ? { ...l, canLoadMore: action.canLoadMore, page: action.page, contacts: sortBy(prop('row_order'), [...(l.contacts ? [...l.contacts.filter(c => !action.contacts.find(nc => nc.id === c.id)), ...action.contacts] : [...action.contacts]).filter(c => c.id !== -1), {"id":-1, "isCutOff":true, "row_order": l.cutoff_line, enrolls_id: -l.id}] ) }
                                        : { ...l, canLoadMore: action.canLoadMore, page: action.page, contacts: sortBy(prop('row_order'), [...(l.contacts ? [...l.contacts.filter(c => !action.contacts.find(nc => nc.id === c.id)), ...action.contacts] : [...action.contacts]).filter(c => c.id !== -1)] ) }
                                  : l)
              }
    case UPDATE_LIST_QUERY:
      return { ...state, lists: state.lists.map(l => l.id == action.listId ? {...l, query_settings: action.list.query_settings, contacts: []} : l)}
    case UPDATE_LIST:
      return { ...state, lists: state.lists.map(l => l.id === action.list.id ? action.list : l)}
    case DELETE_LIST:
      return { ...state, lists: state.lists.filter(l => l.id != action.listId)}
    case ADD_LIST:
      return { ...state, lists: [...state.lists, action.list]}
    case SET_ACTIVE_CONTACT:
      return { ...state, activeContact: action.activeContact, fallbackLists: action.fallbackLists}
    case LISTS_ADD_CONTACT:
      return { ...state, lists: state.lists.map(l => l.id == action.listId
                                    ? { ...l, contacts: sortBy(prop('row_order'), [...(l.contacts ? [...l.contacts.filter(c => c.id !== action.contact.id), action.contact] : [action.contact]).filter(c => c.id !== -1), {"id":-1, "isCutOff":true, "row_order": l.cutoff_line, enrolls_id: -l.id}] ) }
                                    : l)
              }
    case LISTS_ADD_ALL_CONTACTS:
      return { ...state, lists: state.lists.map(l => l.id == action.listId
                                  ? { ...l, contacts: sortBy(prop('row_order'), [...(l.contacts ? [...l.contacts.filter(c => !action.contacts.find(nc => nc.id === c.id)), ...action.contacts] : [...action.contacts]).filter(c => c.id !== -1), {"id":-1, "isCutOff":true, "row_order": l.cutoff_line, enrolls_id: -l.id}] ) }
                                  : l)
              }
    case LISTS_REMOVE_CONTACT:
      return { ...state, lists: state.lists.map(l => l.id == action.list.id
                                    ? { ...action.list, contacts: sortBy(prop('row_order'), [...(l.contacts ? [...l.contacts.filter(c => c.id !== action.contact.id)] : []).filter(c => c.id !== -1), {"id":-1, "isCutOff":true, "row_order": action.list.cutoff_line, enrolls_id: -action.list.id}] ) }
                                    : l)
              }
    case LISTS_REMOVE_ALL_CONTACTS:
      return { ...state, lists: state.lists.map(l => l.id == action.list.id
                                    ? { ...action.list, contacts: sortBy(prop('row_order'), [{"id":-1, "isCutOff":true, "row_order": action.list.cutoff_line, enrolls_id: -action.list.id}] ) }
                                    : l)
              }
    case DRAGOVER_LIST:
      return { ...state, lists: [...state.lists.filter(l => l.id != action.listId),
                {...state.lists.find(l => l.id == action.listId), contacts: action.orderedContacts, cutoff_line: action.orderedContacts.find(c=>c.id == -1).row_order}]
              }
    case DRAGOVERMULTI_LIST:
      return { ...state, lists: [...state.lists.filter(l => l.id != action.listId1 && l.id != action.listId2),
                {...state.lists.find(l => l.id == action.listId1), contacts: action.orderedContacts1, cutoff_line: action.orderedContacts1.find(c=>c.id == -1).row_order},
                {...state.lists.find(l => l.id == action.listId2), contacts: action.orderedContacts2, cutoff_line: action.orderedContacts2.find(c=>c.id == -1).row_order}]
              }
    case LISTS_SET_CONTACT_COLOR:
      return { ...state, lists: state.lists.map(l => l.id == action.listId
                ? { ...l, contacts: sortBy(prop('row_order'), [...([...l.contacts.filter(c => c.id != action.contactId), {...l.contacts.find(c => c.id == action.contactId), status_color: action.statusColor}]).filter(c => c.id !== -1), {"id":-1, "isCutOff":true, "row_order": l.cutoff_line, enrolls_id: -l.id}] ) }
                : l)
              }
    default:
      return state;
  }
}
