const initialState = {
  position: [],
  loading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_POSITIONS':
      return {...state, position: action.positions}
    case 'FETCH_POSITIONS':
      return { ...state, loading: true }
    case 'FETCH_POSITIONS_COMPLETE':
      return { ...state, loading: false }
    case 'ADD POSITION':
      return {...state, position: [ ...state.position, action.position ]}
    case 'GENERATE_POSITIONS':
      return {...state, position: [...action.positions]}
    case 'UPDATE POSITION':
      return {...state,
              position: state.position.map(p => p.id === action.position.id ? action.position : p)}
    case 'DESTROY POSITION':
      return {...state, position: state.position.filter(p => p.id !== action.id)}
    default:
      return state
  }
}
