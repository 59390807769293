import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'

const initialState = {
  team_import_integration: [],
  manual_import_integration: {id: ''},
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
  loading: false,
  loadingSaveAndTest: false,
}

const coachboard_team_import_integrations = (state = initialState, action) => {
  const { team_import_integration } = state
  switch (action.type) {
    case 'FETCH_SAVE_AND_TEST':
      return { ...state, loadingSaveAndTest: true }
    case 'FETCH_SAVE_AND_TEST_COMPLETE':
      return { ...state, loadingSaveAndTest: false }
    case 'GET_TEAM_IMPORT_INTEGRATIONS':
      return { ...state, team_import_integration: action.team_import_integration }
    case 'FETCH_TEAM_IMPORT_INTEGRATIONS':
      return { ...state, loading: true }
    case 'FETCH_TEAM_IMPORT_INTEGRATIONS_COMPLETE':
      return { ...state, loading: false }
    case 'ADD_TEAM_IMPORT_INTEGRATION':
      return { ...state, team_import_integration: [ ...state.team_import_integration, action.team_import_integration ] }
    case 'UPDATE_TEAM_IMPORT_INTEGRATION':
      return {
        ...state,
        team_import_integration: state.team_import_integration.map(s => s.id === action.team_import_integration.id ? action.team_import_integration : s),
      }
    case 'UPDATE_MANUAL_IMPORT_INTEGRATION':
      return { ...state, manual_import_integration: action.team_import_integration }
    case 'DESTROY_TEAM_IMPORT_INTEGRATION':
      // get staff index that was deleted
      const deletedTeamImportIntegrationIndex = team_import_integration.findIndex(({ id }) => id === action.id)
      // add deleted_at to deleted staff
      // get actual deleted_at value from server
      const deletedTeamImportIntegration = {
        ...team_import_integration[deletedTeamImportIntegrationIndex],
        deleted_at: new Date().toISOString(),
      }
      // replace previous staff state with updated version
      return {
        ...state,
        team_import_integration: [
          ...team_import_integration.slice(0, deletedTeamImportIntegrationIndex),
          deletedTeamImportIntegration,
          ...team_import_integration.slice(deletedTeamImportIntegrationIndex + 1),
        ],
      }
    case 'RESET_TEAM_IMPORT_INTEGRATIONS':
      return {
        ...state,
        team_import_integration: [],
      }
    case 'REACTIVATE_TEAM_IMPORT_INTEGRATION':
      const updatedTeamImportIntegrationIndex = team_import_integration.findIndex(({ id }) => id === action.id)
      const updatedTeamImportIntegration = {
        ...team_import_integration[updatedTeamImportIntegrationIndex],
        deleted_at: null,
      }
      return {
        ...state,
        team_import_integration: [
          ...team_import_integration.slice(0, updatedTeamImportIntegrationIndex),
          updatedTeamImportIntegration,
          ...team_import_integration.slice(updatedTeamImportIntegrationIndex + 1),
        ],
      }
    
    default:
      return state
  }
}

export default coachboard_team_import_integrations
