import {
  SET_ANALYTICS_CONTACTS,
  SET_MORE_ANALYTICS_CONTACTS,
  UPDATE_ANALYTICS_CONTACTS_QUERY,
  LOADING_ANALYTICS_CONTACTS,
  SET_CONTACT_MESSAGE_HISTORY,
  SET_ANALYTICS_CONVERSATION,
  GET_CALL_TAGS,
  TAG_CALL,
  LOADING_MESSAGE_HISTORY,
  LOADING_ANALYTICS_CONVERSATION
} from '../actions/types'

const defaultState = {
  contact: {},
  contacts: [],
  messageHistory: [],
  conversation: [],
  callTags: {},
  query: '',
  contactsLoading: false,
  contactsFetched: false,
  messageHistoryLoading: false,
  conversationLoading: false,
  canLoadMoreContacts: false,
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case LOADING_ANALYTICS_CONTACTS:
      return { ...state, contactsLoading: true }
    case SET_ANALYTICS_CONTACTS:
      return { ...state, contacts: action.data.analytics, contactsLoading: false, contactsFetched: true, canLoadMoreContacts: action.data.canLoadMore }
    case SET_MORE_ANALYTICS_CONTACTS:
      return {
        ...state,
        contacts: [...state.contacts, ...action.data.analytics],
        contactsLoading: false,
        contactsFetched: true,
        canLoadMoreContacts: action.data.canLoadMore }
    case UPDATE_ANALYTICS_CONTACTS_QUERY:
      return { ...state, query: action.query }
    case LOADING_MESSAGE_HISTORY:
      return { ...state, messageHistoryLoading: true }
    case SET_CONTACT_MESSAGE_HISTORY:
      return { ...state, messageHistory: action.data, messageHistoryLoading: false }
    case LOADING_ANALYTICS_CONVERSATION:
      return { ...state, conversationLoading: true }
    case SET_ANALYTICS_CONVERSATION:
      return { ...state, conversation: action.activities, contact: action.contact, conversationLoading: false }
    case GET_CALL_TAGS:
      return { ...state, callTags: action.data }
    case TAG_CALL: {
      let conversation = state.conversation.map(c => c.id === action.id ? {...c, call_tag: action.tag} : c)
      return { ...state, conversation }
    }
    default:
      return state
  }
}
