import {
  RECEIVE_REPORT_DEF,
  CREATE_REPORT_DEF,
  UPDATE_REPORT_DEF,
  DELETE_REPORT_DEF,
  REPLACE_REPORT_DEF,
  RECEIVE_REPORT_DEF_OPTIONS,
  RECEIVE_REPORT_DEF_COLUMNS
} from '../actions/types'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'

const initialState = {
  reports: [],
  isLoaded: false,
  report_types: [],
  report_type_columns: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_REPORT_DEF_OPTIONS:
      return { ...state, report_types: action.report_types }
    case RECEIVE_REPORT_DEF_COLUMNS:
      return { ...state, report_type_columns: action.report_type_columns }
    case RECEIVE_REPORT_DEF:
      return { ...state, reports: action.reports, isLoaded: true }
    case CREATE_REPORT_DEF:
      return { ...state, reports: [...state.reports, action.report] }
    case UPDATE_REPORT_DEF:
      return { ...state, reports: state.reports.map(f => f.id === action.report.id ? { ...f, ...action.report } : f) }
    case DELETE_REPORT_DEF:
      return {
        ...state,
        reports: state.reports.filter(f => f.id !== action.report.id)
      }
    case REPLACE_REPORT_DEF:
      const reports = [...action.reports, ...state.reports]
      return { ...state, reports: uniqBy(prop('id'), reports) }
    default:
      return state
  }
}
