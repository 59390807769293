import {
  OPEN_CONTEXT_MENU,
  CLOSE_CONTEXT_MENU,
  SET_CONTEXT_MENU_ACTIONS
} from '../actions/types'

const initialState = {
  x: 0,
  y: 0,
  contextMenuOpen: false,
  contextMenuActions: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_CONTEXT_MENU:
      return { ...state, contextMenuOpen: true, x: action.x, y: action.y }
    case CLOSE_CONTEXT_MENU:
      return { ...state, contextMenuOpen: false }
    case SET_CONTEXT_MENU_ACTIONS:
      return { ...state, contextMenuActions: action.actions }
    default:
      return state;
  }
}
