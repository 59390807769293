import {
    SET_LOADING_FLASH,
    CLEAR_LOADING_FLASH,
  } from '../actions/types'
  
  const defaultState = {
    display: false
  }
  
  const loadingFlash = (state = defaultState, action) => {
    switch (action.type) {
      case SET_LOADING_FLASH:
        return { display: true }
      case CLEAR_LOADING_FLASH:
        return { display: false }
      default:
        return state
    }
  }
  
  export default loadingFlash
  