import {
  LOAD_PIPELINES,
  LOADED_PIPELINES,
  LOADING_PIPELINES,
  LOAD_PIPELINE_CONTACTS,
  ADD_PIPELINE,
  ADD_PIPELINE_STAGE,
  ADD_PIPELINE_RATING,
  UPDATE_PIPELINE,
  UPDATE_PIPELINE_STAGE,
  UPDATE_PIPELINE_STAGE_ORDER,
  UPDATE_PIPELINE_RATING,
  UPDATE_PIPELINE_RATING_ORDER,
  DELETE_PIPELINE,
  DELETE_PIPELINE_STAGE,
  DELETE_PIPELINE_RATING,
  PIPELINE_ADD_CONTACT,
  PIPELINE_REMOVE_CONTACT,
  PIPELINE_REMOVE_ALL_CONTACTS,
  PIPELINE_REMOVE_UNEVAL_CONTACTS,
} from '../actions/types'

const initialState = {
  pipelines: [],
  loading: true,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_PIPELINES:
      return { ...state, pipelines: action.pipelines }
    case LOADING_PIPELINES:
      return { ...state, loading: true }
    case LOADED_PIPELINES:
      return { ...state, loading: false }
    case LOAD_PIPELINE_CONTACTS:
      return { ...state, pipelines: state.pipelines.map(l => l.id == action.pipelineId
                                  ? { ...l, pipeline_contacts: action.pipeline_contacts}
                                  : l)
              }
    case UPDATE_PIPELINE:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.pipeline.id ? action.pipeline : l)}
    case ADD_PIPELINE:
      return { ...state, pipelines: [...state.pipelines, action.pipeline]}
    case ADD_PIPELINE_STAGE:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.stage.pipeline_id ? ({ ...l, pipeline_stages: [...l.pipeline_stages, action.stage]}) : l)}
    case ADD_PIPELINE_RATING: 
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.rating.pipeline_id ? ({ ...l, pipeline_ratings: [...l.pipeline_ratings, action.rating]}) : l)}
    case UPDATE_PIPELINE_STAGE:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.stage.pipeline_id ? ({ ...l, pipeline_stages: l.pipeline_stages.map(s => s.id === action.stage.id ? action.stage : s)}) : l)}
    case UPDATE_PIPELINE_STAGE_ORDER:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.pipeline_stages[0].pipeline_id ? ({ ...l, pipeline_stages: action.pipeline_stages }) : l)}
    case UPDATE_PIPELINE_RATING:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.rating.pipeline_id ? ({ ...l, pipeline_ratings: l.pipeline_ratings.map(s => s.id === action.rating.id ? action.rating : s)}) : l)}
    case UPDATE_PIPELINE_RATING_ORDER:
      return { ...state, pipelines: state.pipelines.map(l => l.id === action.pipeline_ratings[0].pipeline_id ? ({ ...l, pipeline_ratings: action.pipeline_ratings }) : l)}
    case DELETE_PIPELINE:
      return { ...state, pipelines: state.pipelines.filter(l => l.id != action.pipeline.id)}  
    case DELETE_PIPELINE_STAGE:
      return {
        ...state,
        pipelines: state.pipelines.map(l => l.id === action.stage.pipeline_id ? { ...l, pipeline_stages: l.pipeline_stages.filter(s => s.id != action.stage.id)} : l)
      }
    case DELETE_PIPELINE_RATING:
      return {
        ...state,
        pipelines: state.pipelines.map(l => l.id === action.rating.pipeline_id ? { ...l, pipeline_ratings: l.pipeline_ratings.filter(r => r.id != action.rating.id)} : l)
      }
     case PIPELINE_ADD_CONTACT:
        //Update only the pipeline passed in and add/update the contact in the list
        return { ...state, pipelines: state.pipelines.map(l => l.id == action.pipelineId
                                     ? {...l, pipeline_contacts: l.pipeline_contacts 
                                          ? [...l.pipeline_contacts.filter(pc => action.pipeline_contacts.find(pcn => pc.id !== pcn.id)), ...action.pipeline_contacts]
                                          : [action.pipeline_contacts] }
                                     : l)
               }
    case PIPELINE_REMOVE_CONTACT:
     return { ...state, pipelines: state.pipelines.map(l => l.id == action.pipelineId
                                    ? { ...l, pipeline_contacts: [...l.pipeline_contacts 
                                                              ? [...l.pipeline_contacts.filter(pc => pc.contact_id !== action.contactId)]
                                                              : []] }
                                    : l)
              }
    case PIPELINE_REMOVE_ALL_CONTACTS:
      return { ...state, pipelines: state.pipelines.map(l => l.id == action.pipelineId
                                      ?  {...l, pipeline_contacts: []  }
                                      : l)
                }
    case PIPELINE_REMOVE_UNEVAL_CONTACTS:
      return { ...state, pipelines: state.pipelines.map(l => l.id == action.pipelineId
                                     ?  {...l, pipeline_contacts: action.contactIds.length == 0 ? [...l.pipeline_contacts] : [...l.pipeline_contacts.filter(pc => !action.contactIds.includes(pc.contact_id) )]  }
                                     : l)
               }
    default:
      return state;
  }
}
