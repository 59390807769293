import {
  LOAD_STAFF_POSTS_POSTED,
  LOADING_STAFF_POSTS,
  LOADED_STAFF_POSTS,
  LOAD_STAFF_POSTS_SCHEDULED,
  REMOVE_SCHEDULED_POST,
  UPDATE_POSTING,
  CLEAR_POSTING_FORM,
  SET_POSTING_FORM
} from '../actions/types'

const initialState = {
  postedPosts: [],
  loading: false,
  postsCanLoadMore: false,
  postsPages: false,
  scheduledPosts: [],
  scheduledCanLoadMore: false,
  scheduledPages: 0,

  post: {
    body: '',
    schedule_at: '',
    previewItem: {},
    attachment: {},
    storageFileId: null,
    schedule_on_hold: false,
    id: null,
  }

}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_STAFF_POSTS_POSTED:
      return { ...state, postedPosts: action.postedPosts, postedCanLoadMore: action.postedCanLoadMore, postedPages: action.postedPages }
    case LOADING_STAFF_POSTS:
      return { ...state, loading: true }
    case LOADED_STAFF_POSTS:
      return { ...state, loading: false }
    case LOAD_STAFF_POSTS_SCHEDULED:
      return { ...state, scheduledPosts: action.scheduledPosts, scheduledCanLoadMore: action.scheduledCanLoadMore, scheduledPages: action.scheduledPages }
    case REMOVE_SCHEDULED_POST:
      return {...state, scheduledPosts: state.scheduledPosts.filter(s => s.id !== action.id)}
    case UPDATE_POSTING:
      return { ...state, post: {...state.post, [action.key]: action.value } }
    case CLEAR_POSTING_FORM:
      return { ...state, post: initialState.post }
    case SET_POSTING_FORM:
      return { ...state, post: {id: action.staff_post.id, previewItem: {}, body: action.staff_post.body, schedule_at: action.staff_post.schedule_at || '', attachment: action.staff_post.attachment || {}, storageFileId: action.staff_post.storage_file_id, schedule_on_hold: action.staff_post.schedule_on_hold}}
    default:
      return state;
  }
}
