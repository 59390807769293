import startOfMonth from 'date-fns/start_of_month'
import endOfMonth from 'date-fns/end_of_month'

const initialState = {
  teams: [],
  selectedTeam: '',
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
  loading: false,
}

const coachboard_teams = (state = initialState, action) => {
  switch (action.type) {
    case 'TEAMS':
      return { ...state, teams: action.teams }
    case 'FETCH_TEAMS':
      return { ...state, loading: true }
    case 'FETCH_TEAMS_COMPLETE':
      return { ...state, loading: false }
    case 'SELECT_TEAM':
      return { ...state, selectedTeam: action.teamId }
    case 'ADD_TEAM':
      return { ...state, teams: [ ...state.teams, action.team ] }
    case 'UPDATE_TEAM':
      return { ...state, teams: state.teams.map(t => t.id === action.team.id ? action.team : t) }
    case 'DESTROY_TEAM':
      return { ...state, teams: state.teams.filter(t => t.id !== action.id) }
    case 'SET_DATE_TEAM':
      return {
        ...state,
        [action.dateType]: action.date,
      }
    default:
      return state
  }
}

export default coachboard_teams
