import axios from 'axios'
import {
  LOGGED_OUT,
  UPDATE_AUTH,
  FETCHING_AUTH,
  LOGGED_IN,
  VALIDATE_TOKEN,
  RECEIVE_ONBEHALF,
  UPDATE_USER,
  UPDATE_TWITTER_DATA,
  CLEAR_COACH_CURRENT_CONTACT,
  UPDATE_TERMS_AGREEMENT,
  UPDATE_DEFAULT_SEND_METHOD,
  UPDATE_FACEBOOK_DATA
} from './types'
import { setHeaders } from '../actions/headers';
import { setFlash, setErrorFlash, setErrorFlashWithMessage } from './flashActions'
import { loadWIDFields, loadWIDFunctions } from './wIDFieldsActions'
import { loadCoachFields } from './coachActions'
import { loadPositions } from './sportPositionsActions'
import { loadTeamInfo } from './v2/teamActions'
import { loadTeamWids } from './teamWidsActions'
import { getAssociatedStaffs } from './v2/staffsActions'
import { loadPipelines } from './v2/teamPipelinesActions'
import {loadReportDefs } from './reportDefActions'

export const logout = () => dispatch => {
  dispatch({ type: LOGGED_OUT })
}

export const updateAuth = headers => dispatch => dispatch({ type: UPDATE_AUTH, headers })

export const fetchingAuth = () => dispatch => dispatch({ type: FETCHING_AUTH })

export const auth = (email, password) => dispatch => {
  dispatch(fetchingAuth())
  return axios.post(`/api/v1/auth/sign_in`, { email, password })
    .then(({ data, headers }) => {
      dispatch({ type: LOGGED_IN, data: data.data, headers })
      dispatch(loadReducerValues(data.data))
     // window.gtag('event', 'Login New')
      return { ok: true }
    })
    .catch(res => {
      if (res.response && res.response.status === 401) {
        dispatch(setFlash("Invalid email or password", 'error'))
      } else if (res.response && res.response.status === 402) {
        dispatch(setFlash("Password has been expired. Check your email for a confirmation link or Contact Support.", 'error'))
      } else if (res.response && res.response.status === 403) {
        dispatch(setFlash("This account has been deactivated or has not been confirmed. Check your email for a confirmation link or Contact Support.", 'error'))
      } else if (res.response && res.response.status === 500) {
        dispatch(setFlash("There was a problem connecting to Recruiting Communication, please contact support.", 'error'))
      } else {
        dispatch(setFlash(res.message, 'error'))
      }
      dispatch(logout())
    })
}


const loadReducerValues = (coach) => dispatch => {
    // preload the reducers with these values
    if (coach.kind == 'coach' || coach.kind == 'manager') {
      dispatch(loadWIDFields())
      dispatch(loadWIDFunctions())
      dispatch(loadTeamInfo(coach.school_id))
      dispatch(loadTeamWids())
      dispatch(getAssociatedStaffs())
      coach.allow_pipeline && dispatch(loadPipelines())
      dispatch(loadReportDefs())
      dispatch(loadCoachFields())
      dispatch(loadPositions())
    } else if (coach.kind == 'compliance') {
      dispatch(getAssociatedStaffs())
    }
}

export const authsso = (code, auth_service = 'teamworks') => dispatch => {
  return axios.post(`/api/v1/auth/sign_in`, { code, auth_service: auth_service })
    .then(({ data, headers }) => {
       dispatch({ type: LOGGED_IN, data: data.data, headers })
       dispatch(loadReducerValues(data.data))
      // window.gtag('event', 'Login New')
       return { ok: true }
    })
    .catch(res => {
      dispatch(setErrorFlash(res))
      dispatch(logout())
      return { ok: false }
    })
}

export const ssoUrl = () => dispatch => {
  return axios.get(`/api/v2/oauth/teamworks/get_sso_url`)
    .then(({data: {url, sso_state}}) => {
      localStorage.setItem('teamworks-sso-state', sso_state)
      return { ok: true, url: url}
    })
    .catch((e) => {
      dispatch(setErrorFlash(e))
      return {ok: false}
    })
}

export const iosStoreUrl = () => async dispatch => {
  return axios.get(`/api/v2/oauth/teamworks/get_ios_store_url`)
    .then(({data: {url}}) => {
      return url
    })
    .catch((e) => {
      dispatch(setErrorFlash(e))
    })
}

export const androidStoreUrl = () => async dispatch => {
  return axios.get(`/api/v2/oauth/teamworks/get_android_store_url`)
    .then(({data: {url}}) => {
      return url
    })
    .catch((e) => {
      dispatch(setErrorFlash(e))
    })
}

export const validateAuth = token => dispatch => {
  dispatch({ type: VALIDATE_TOKEN })
  let headers = axios.defaults.headers.common
  return axios.get(`/api/v1/auth/validate_token`, headers)
    .then(({ data, headers }) => {
      dispatch({ type: LOGGED_IN, data: data.data, headers })
      dispatch(loadReducerValues(data.data))
     // window.gtag('event', 'Login Token')
    })
    .catch(res => {
      if (res.response && res.response.status === 401) {
        dispatch(setFlash("Your session has expired, please login again.", 'error'))
      } else if (res.response && res.response.status === 500) {
        dispatch(setFlash("There was a problem connecting to Recruiting Communication, please contact support.", 'error'))
      } else if (res.message) {
        dispatch(setFlash(res.message, 'error'))
      }
      dispatch(logout())
    })
}

export const updateUser = (userId, user) => dispatch =>
  axios.put(`/api/v1/staffs/${userId}`, user)
    .then(({ data, headers }) => {
      dispatch({ type: UPDATE_USER, data, headers })
      dispatch(setFlash(`${data.name} updated.`, 'success'))
      return { ok: true, data, headers }
    })
    .catch(res => {
      if (res.response && res.response.status == 403) {
        dispatch(setErrorFlashWithMessage(res, "Attachment must be in an acceptable format (jpg, png, etc.)"))
        return { ok: false }
      }

      dispatch(setErrorFlash(res))
      return { ok: false, res }
    })

export const updateUserRedirect = (userId, user, location, history) => dispatch =>
  axios.put(`/api/v1/staffs/${userId}`, user)
    .then(({ data, headers }) => {
      dispatch({ type: UPDATE_USER, data, headers })
      history.push(location)
      return { ok: true, data, headers }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false, response }
    })

export const loadOnBehalf = () => dispatch =>
  axios.get(`/api/v1/staffs/on_behalf_users`)
    .then(({ data, headers }) => {
      dispatch({ type: RECEIVE_ONBEHALF, data: data.coaches, headers })
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
    })

export const handleLogout = () => dispatch =>
  axios.delete(`/api/v1/auth/sign_out`)
    .then(res => {
      dispatch(logout())
      dispatch(setFlash('Logged out successfully!', 'success'))
      dispatch(setHeaders(res.headers))
    })
    .catch(res => {
      if (res.response && res.response.statusText) {
        dispatch(setErrorFlash(res))
      } else {
        dispatch(setFlash("There was a problem connecting to Recruiting Communication, please contact support.", 'error'))
      }
      dispatch(logout())
    })

export const fetchCoach = (coachId) => dispatch =>
  axios.get(`/api/v1/staffs/fetch_coach`, { headers: { onbehalf: coachId } })
    .then(({ data }) => {
      return { ok: true, coach: data }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

export const updateTermsAgreement = coachId => dispatch =>
  axios.put('/api/v2/staffs/update_terms_agreement', { headers: { onbehalf: coachId } })
    .then(() => {
      dispatch({ type: UPDATE_TERMS_AGREEMENT })
      return { ok: true }
    })
    .catch(response => {
      dispatch(setErrorFlash(response))
      return { ok: false }
    })

export const updateTwitterData = coach => dispatch => dispatch({ type: UPDATE_TWITTER_DATA, coach, coachId: coach.id })

export const updateFacebookData = coach => dispatch => dispatch({ type: UPDATE_FACEBOOK_DATA, coach, coachId: coach.id })

export const clearCoachCurrentContact = coachId => dispatch => dispatch({ type: CLEAR_COACH_CURRENT_CONTACT, coachId })

export const updateDefaultSendMethod = (coachId, defaultSendMethod) => dispatch => 
  axios.put(
    '/api/v2/staffs/update_default_send_method',
    { default_send_method: defaultSendMethod },
    { headers: { onbehalf: coachId } }
  )
  .then(() => {
    dispatch({ type: UPDATE_DEFAULT_SEND_METHOD, coachId: coachId, defaultSendMethod: defaultSendMethod })
  })
  .catch(response => {
    dispatch(setErrorFlash(response))
  })
